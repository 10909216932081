import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms'
import {addHours, addMinutes, getHours, getMinutes, subHours} from 'date-fns'
import {ValueItemList} from '../cadastros/cct-edit/componentes/agiben-bill-preview/agiben-bill-preview.component'
import {AtividadeStatusEnum} from '../model/atividades/atividade-status-enum'
import {EnumTipoAtividadeCalendario} from '../model/atividades/atividade-tipo-calendario.enum'
import {AtividadeTipo} from '../model/atividades/atividade-tipo.enum'
import {TipoAtividadeCompromisso} from '../model/atividades/tipo-atividade-compromisso.enum'
import {Client} from '../model/client'
import {SistemaOperacional} from '../model/sistema-operacional'
import {ERROR_MESSAGES} from './constants'
import {CompromissoStatusEnum, Meses, TipoValidoOuInvalido} from './enums'

/**
 * [Função para formatar "DateTime" em formato de horas "00:00"]
 * @param  date [data no formate "DateTime" para ser formatada em "00:00"]
 * @return [retorna a hora/minutos preenchidos com 0]
 */
export const converterDateStringParaHorasEMinutos = (date: string): string => {
	const [hora, minutos] = date.split('T')[1].split(':')
	const horaFormatada = `${hora.padStart(2, '0')}:${minutos.padStart(2, '0')}`
	return horaFormatada
}

/**
 * [Função para preencher as horas e minutos com zero quando necessário]
 * @param  horaParaFormatar [string no formato "xx:xx" para ser formatada com "0"]
 * @return [retorna a hora/minutos preenchidos com 0]
 */
export const retornarHoraMinutoPreenchidoCom0 = (horaParaFormatar: string): string => {
	const [hora, minutos] = horaParaFormatar.split(':')
	const horaFormatada = `${hora.padStart(2, '0')}:${minutos.padStart(2, '0')}`
	return horaFormatada
}

/**
 * [Função para tratamento de erros]
 * @param  error [obj de erro ocorrido na requisição]
 * @return [mensagem de erro retornada de acordo com o status do erro]
 */
export const retornarMsgErroPorStatus = (error: any): string => {
	console.log(error)
	let msgErro = 'Ocorreu um erro, tente novamente mais tarde.'
	if (error?.status) {
		switch (error?.status) {
			case 404:
				msgErro = 'Erro ao realizar procedimento.'
				break
			case 400:
			case 418:
				msgErro = error?.error?.message || error?.error?.message[0] || error?.message[0]
				break
		}
	}
	return msgErro
}

/**
 * [Função para verificar se o caracter é uppercase]
 * @param  string [string para ser verificada]
 * @return  [retorna booleano verdadeiro se for uppercase]
 */
export const verificarUpperCase = (str: string): boolean => {
	return /^[A-Z]*$/.test(str)
}

/**
 * [Função para verificar se o caracter especial está companhado de ]
 * @param  string [string para ser verificada]
 * @return  [retorna booleano verdadeiro se for especial]
 */
export const verificarCaracterEspecial = (str: string): boolean => {
	return /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(str)
}

/**
 * [Função para renomear nome de arquivo ]
 * @param  fileOriginal [arquivo para ser modificado]
 * @param  newName [novo nome para ser alterado]
 * @return [retorna booleano verdadeiro se for especial]
 */
export const renomearNomeArquivo = (fileOriginal: File, newName: string) => {
	let formattedName: string = newName
	if (fileOriginal.name.includes('.')) {
		const fileOriginalNameParts = fileOriginal.name.split('.')
		formattedName = newName + '.' + fileOriginalNameParts[fileOriginalNameParts.length - 1]
	}

	const newFile = new File([fileOriginal], formattedName, {
		type: fileOriginal.type,
		lastModified: fileOriginal.lastModified
	})
	return newFile
}

/**
 * [Função para converter um arrayBuffer em obj Json]
 * @param  arrayBuffer [arrayBuffer passado como parametro inicial]
 * @return [ArrayBuffer formatado]
 */
export const converterArrayBufferEmObj = (arrayBuffer: ArrayBuffer) => {
	return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
}

/**
 * [Função para gerar um Date baseado em dois campos string Data e Hora]
 * @param  data [Data em string no formato dd-mm-yyyy]
 * @param  hora [Hora em string no formato Hh:m0]
 * @return tipo Date dos paramêtros passados]
 */
export const getDateFormatComHoraMinutoESegundo = (data, hora) => {
	return new Date(`${data}T${hora.padStart(5, '0')}`)
}

/**
 * [Função para validar um CNPJ]
 * @param  cnpj [CNPJ]
 * @return  [resultado da validação]
 */
export const validateCNPJ = cnpj => {
	cnpj = cnpj.trim().replace(/[^\d]+/g, '')
	let v1 = 0
	let v2 = 0
	let aux = false

	for (let i = 1; cnpj.length > i; i++) {
		if (cnpj[i - 1] !== cnpj[i]) {
			aux = true
		}
	}

	if (aux === false) {
		return false
	}

	for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v1 += cnpj[i] * p1
		} else {
			v1 += cnpj[i] * p2
		}
	}

	v1 = v1 % 11

	if (v1 < 2) {
		v1 = 0
	} else {
		v1 = 11 - v1
	}

	if (v1 !== cnpj[12]) {
		return false
	}

	for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v2 += cnpj[i] * p1
		} else {
			v2 += cnpj[i] * p2
		}
	}

	v2 = v2 % 11

	if (v2 < 2) {
		v2 = 0
	} else {
		v2 = 11 - v2
	}

	if (v2 !== cnpj[13]) {
		return false
	} else {
		return true
	}
}

/**
 * [Função para validar um CPF]
 * @param  cpf [CPF]
 * @return  [resultado da validação]
 */
export const validateCPF = cpf => {
	let Soma = 0
	let Resto

	const strCPF = String(cpf).replace(/[^\d]/g, '')

	if (strCPF.length !== 11) {
		return false
	}

	if (
		[
			'00000000000',
			'11111111111',
			'22222222222',
			'33333333333',
			'44444444444',
			'55555555555',
			'66666666666',
			'77777777777',
			'88888888888',
			'99999999999'
		].indexOf(strCPF) !== -1
	) {
		return false
	}

	for (let i = 1; i <= 9; i++) {
		Soma = Soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i)
	}

	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) {
		Resto = 0
	}

	if (Resto !== parseInt(strCPF.substring(9, 10), 10)) {
		return false
	}

	Soma = 0

	for (let i = 1; i <= 10; i++) {
		Soma = Soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i)
	}

	Resto = (Soma * 10) % 11

	if (Resto === 10 || Resto === 11) {
		Resto = 0
	}

	if (Resto !== parseInt(strCPF.substring(10, 11), 10)) {
		return false
	}

	return true
}

export const setValidators = (control: AbstractControl, validators: ValidatorFn[]) => {
	if (control) {
		control.setValidators(validators)
		control.updateValueAndValidity()
	}
}

/**
 * [Função para gerar uma data no formato propricio para o NgbDatepicker baseado em um Date]
 * @param  data [Data no tipo Date]
 * @return [Data formatada]
 */
export const formatarDateParaDatePicker = (data: Date) => {
	const dateFormatada = {
		year: data.getFullYear(),
		month: data.getMonth() + 1,
		day: data.getDate()
	}
	return dateFormatada
}

/**
 * [Função para gerar um Date baseado apenas no mês e no ano]
 * @param  mes [Mês no formato MM]
 * * @param  ano [Ano no formato YYYY]
 * @return [Date]
 */
export const gerarDateBaseadoEmMesAno = (mes: string, ano: string) => {
	const date = new Date()
	date.setFullYear(Number(ano), Number(mes) - 1)
	return date
}

interface PegarSistemaOperacional {
	client: Client
	sistemaOperacional: SistemaOperacional
}
export const pegarSistemaOperacional = (): PegarSistemaOperacional => {
	const userAgent = window.navigator.userAgent.toLowerCase()

	if (userAgent.indexOf('windows') !== -1) {
		return {
			client: 'WEB',
			sistemaOperacional: 'WINDOWS'
		}
	} else if (userAgent.indexOf('android') !== -1) {
		return {
			client: 'MOBILE',
			sistemaOperacional: 'ANDROID'
		}
	} else if (userAgent.indexOf('linux') !== -1) {
		return {
			client: 'WEB',
			sistemaOperacional: 'LINUX'
		}
	} else if (userAgent.indexOf('mac') !== -1) {
		return {
			client: 'WEB',
			sistemaOperacional: 'MAC'
		}
	} else if (
		userAgent.indexOf('iphone') !== -1 ||
		userAgent.indexOf('ipad') !== -1 ||
		userAgent.indexOf('ipod') !== -1
	) {
		return {
			client: 'MOBILE',
			sistemaOperacional: 'IOS'
		}
	} else {
		return {
			client: 'OUTRO',
			sistemaOperacional: 'OUTRO'
		}
	}
}

/**
 * [Função para retornar nome do arquivo sem extensão ]
 * @param  nomeArquivoOriginal [nome do arquivo original]
 * @return [nome do arquivo sem extensão]
 */
export const formatarNomeArquivoSemExtensao = (nomeArquivoOriginal: string) => {
	const arrNomeArquivoOriginal = nomeArquivoOriginal.split('.')
	return arrNomeArquivoOriginal[0]
}

/**
 * [ Função para retornar texto sem caracteres especiais ]
 * @param  texto [texto original]
 * @return [texto sem caracteres]
 */
export const substituirCaracteresEspeciais = (texto: string) => {
	return texto
		.normalize('NFD')
		.replace(/\\/g, '')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[^\w\s.]/g, '')
}

/**
 * [ Função para retornar status de validade dos controles de um form, a depender da opção passada ]
 * @param  formGroup [Form passado]
 * @param  opcao [Opcao que pode ser invalido ou valido]
 * @return [lista de com as chaves(keys) dos controles]
 */
export const listarControlesInvalidosDeUmForm = (
	formGroup: FormGroup,
	opcao: TipoValidoOuInvalido
) => {
	const controls = Object.keys(formGroup.controls).map(key => {
		switch (opcao) {
			case TipoValidoOuInvalido.INVALID:
				if (formGroup.get(key).invalid) {
					return key
				}
				break
			case TipoValidoOuInvalido.VALID:
				if (formGroup.get(key).valid) {
					return key
				}
				break
		}
	})
	return controls
}

/**
 * [Função para formatar valor em br]
 * @param  formatarValor [valor formatado ex: 11,20]
 * @return valor formatado em br
 */

export function formatarValor(valor: string | undefined): string | undefined {
	if (!valor) {
		return undefined
	}

	return valor?.toString().replace(/\./g, ',')
}

/**
 * [Função para calcular valores do componente de conta]
 * @param  [valores: {totalPafValue: number, agencyValue: number, agencyPercent: number, lifetimeValue: number, administrativeTaxValue: number, listaValorProdutos: number[]}]
 * @return [{availableCreditValue: number, totalUsedValue: number}]
 */
export function calcularSaldoEGasto(totalValue: number, listaValorProdutos: number[]) {
	const totalUsedValue = listaValorProdutos.reduce(
		(somaParcial, value) => somaParcial + Number(value),
		0
	)
	const availableCreditValue = totalValue - totalUsedValue
	return {availableCreditValue, totalUsedValue}
}

/**
 * [Função para obter cálculo do valor de agenciamento]
 * @param  [totalPafValue: number, agencyPercent:number, months: number = 12]
 * @return [agencyValue: number]
 */
export function obterValorAbsolutoAgenciamento(
	totalPafValue: number,
	agencyPercent: number,
	months: number = 12
) {
	return (totalPafValue / months) * (agencyPercent / 100)
}

/**
 * [Função para montar lista de valores de PAF para componente de conta]
 * @param  [allowAgency: boolean,
 * agencyValue: number,
 * lifetimeValue: number,
 * administrativeTaxValue: number,
 * products: ValueItemList[]]
 * @return [valueList: ValueItemList[]]
 */
export function montarListaValoresPAF(
	allowAgency: boolean,
	agencyValue: number,
	lifetimeValue: number,
	administrativeTaxValue: number,
	products: ValueItemList[]
) {
	const valueList: ValueItemList[] = []
	if (allowAgency) {
		valueList.push({
			descricao: 'Agenciamento',
			percentual: true,
			valor: agencyValue
		})
	}

	valueList.push({
		descricao: 'Vitalício',
		percentual: false,
		valor: lifetimeValue
	})

	valueList.push({
		descricao: 'Taxa administrativa',
		percentual: false,
		valor: administrativeTaxValue
	})

	if (products.length) {
		for (const product of products) {
			valueList.push({
				id: product.id,
				valor: product.valor,
				descricao: product.descricao,
				percentual: false
			})
		}
	}

	return valueList
}

export const calcularSaldoAgenciamentoEVitalicio = (
	valorPercentualAgenciamento: number,
	valorPaf: number,
	valorAgenciamentoSindicatos: number[],
	valorAgenciamentoIntermediadores: number[],
	valorVitalicioSindicatos: number[],
	valorVitalicioIntermediadores: number[],
	valorVitalicio: number
) => {
	const totalMesesPaf = 12
	const valorAgenciamento = (valorPaf / totalMesesPaf) * (valorPercentualAgenciamento / 100)

	const agenciamentoSindicatos = valorAgenciamentoSindicatos.map(valor =>
		valor === undefined ? 0 : valor
	)
	const agenciamentoIntermediadores = valorAgenciamentoIntermediadores.map(valor =>
		valor === undefined ? 0 : valor
	)
	const vitalicioSindicatos = valorVitalicioSindicatos.map(valor =>
		valor === undefined ? 0 : valor
	)
	const vitalicioIntermediadores = valorVitalicioIntermediadores.map(valor =>
		valor === undefined ? 0 : valor
	)

	const totalAgenciamento =
		agenciamentoSindicatos.reduce(
			(somaParcial, percentual) => somaParcial + (valorAgenciamento * percentual) / 100,
			0
		) +
		agenciamentoIntermediadores.reduce(
			(somaParcial, percentual) => somaParcial + (valorAgenciamento * percentual) / 100,
			0
		)

	const creditoDisponivelAgenciamento = valorAgenciamento - totalAgenciamento

	const totalVitalicio =
		vitalicioSindicatos.reduce((somaParcial, valor) => somaParcial + Number(valor), 0) +
		vitalicioIntermediadores.reduce((somaParcial, valor) => somaParcial + Number(valor), 0)

	const creditoDisponivelVitalicio = valorVitalicio - totalVitalicio

	return {
		creditoDisponivelAgenciamento,
		creditoDisponivelVitalicio,
		valorAgenciamento,
		totalAgenciamento,
		totalVitalicio
	}
}

export function formatarDataParaDiaMesAno(data: string): string {
	const [ano, mes, dia] = data.split('T')[0].split('-')

	return `${dia}/${mes}/${ano}`
}

export function formatarData(data: string): string {
	return !data ? '' : data.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1')
}

export function formatarStringParaCamelCase(str: string): string {
	const words = str.toLowerCase().split(' ')
	const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
	return capitalizedWords.join(' ')
}

export function formatId(id?: string): string {
	if (!id) {
		return ''
	}
	if (id.length === 5) {
		return id.replace(/(\d{2})(\d{2})(\d{1})/, '$1.$2.$3')
	} else if (id.length === 7) {
		return id.replace(/(\d{2})(\d{2})(\d{3})/, '$1.$2.$3')
	}
	return id
}

/**
 * [Função para retornar lista de meses como options de select ]
 * @return [retorna lista de meses como options de select]
 */
export function listarMeses() {
	return Object.keys(Meses)
		.filter(key => isNaN(Number(key)))
		.map(key => {
			return {label: key, value: key}
		})
}

/**
 * [Função para conveter kilo bytes em mega bytes aredondando em duas casas decimais]
 * @return [retorna o valor convertido]
 */
export function kbConverToMB(value: number) {
	return value > 0 ? parseFloat((value / (1024 * 1024)).toFixed(2)) : 0
}

/**
 * [Função para abrir um arquivo em uma nova janela]
 * @return [retorna o valor convertido]
 */
export function viewBrowserLocalFile(file: File) {
	const fileReader = new FileReader()
	fileReader.onload = () => {
		const blob = new Blob([fileReader.result as ArrayBuffer], {type: file.type})
		abrirNovaAba(URL.createObjectURL(blob))
	}
	fileReader.readAsArrayBuffer(file)
}

export function abrirNovaAba(url: string) {
	window.open(url, '_blank')
}

export function extractFilename(url: string): string {
	// Verifica se a URL contém "?" para identificar query parameters
	const [cleanUrl, queryParams] = url.split('?')

	// Se houver query parameters, retorna o valor após o "?"
	if (queryParams) {
		return queryParams
	}

	// Caso não haja query parameters, divide a URL pelos "/" e obtém a última parte
	const parts = cleanUrl.split('/')
	const lastPart = parts[parts.length - 1]

	// Se a última parte estiver vazia (ex.: URL termina com "/"), pega a penúltima parte
	return lastPart || parts[parts.length - 2]
}

export function capitalize(text) {
	return text?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase())
}

export function gerarHoras() {
	const horas = []
	Array.from({length: 24}, (_, i) => i).map(i => {
		horas.push(`${i.toString().padStart(2, '0')}:00`)
		horas.push(`${i.toString().padStart(2, '0')}:15`)
		horas.push(`${i.toString().padStart(2, '0')}:30`)
		horas.push(`${i.toString().padStart(2, '0')}:45`)
	})
	return horas
}

export function converterDataEHoraParaTimezoneAmericaRecife(date: Date, hora: string) {
	const dataHora = new Date(date)
	const [horaInicio, minutoInicio] = hora.split(':')
	dataHora.setHours(Number(horaInicio))
	dataHora.setMinutes(Number(minutoInicio))

	const getTimeZone = new Date().getTimezoneOffset() / 60
	// TODO: Verificar em DEV se está salvando corretamente.
	return subHours(dataHora, Math.floor(getTimeZone)).toISOString()
}

export function getEnumKeyByValue(enumType: object, value) {
	const entryFound = Object.entries(enumType).find(item => item[1] === value)
	return entryFound ? entryFound[0] : null
}

export function extrairHoraEMinuto(data: string | Date) {
	if (!data) {
		return null
	}

	if (data instanceof Date) {
		const hora = data.getHours().toString().padStart(2, '0')
		const minuto = data.getMinutes().toString().padStart(2, '0')
		return `${hora}:${minuto}`
	}

	if (typeof data === 'string') {
		const [hora, minuto] = data.split('T')[1].split(':')
		return `${hora}:${minuto}`
	}
}

/**
 * Retorna a forma singular ou plural de uma palavra com base no valor fornecido.
 * Esta função ajuda a escolher entre a forma singular e plural de uma palavra, dependendo do número especificado.
 * - Se o valor for 0 ou 1, retorna a forma singular.
 * - Para valores maiores que 1, retorna a forma plural.
 * Se a forma plural não for fornecida, ela será gerada automaticamente adicionando um 's' ao final da forma singular.
 * @param value - O número que determina se a forma singular ou plural deve ser usada.
 * @param singular - A forma singular da palavra.
 * @param plural - A forma plural da palavra (opcional). Se não fornecido, a função irá adicionar um 's' ao final do singular para formar o plural.
 * @returns A forma apropriada da palavra com base no valor fornecido.
 * @example
 * // Exemplo de uso
 * pluralize(0, 'item');       // 'item'
 * pluralize(1, 'item');       // 'item'
 * pluralize(2, 'item');       // 'items'
 * pluralize(5, 'box', 'boxes'); // 'boxes'
 */

export function pluralize(value: number, singular: string, plural?: string): string {
	if (plural === undefined) {
		plural = singular + 's'
	}

	return value === 1 || value === 0 ? singular : plural
}

export function getCompromissoStatus(status): string {
	switch (status) {
		case CompromissoStatusEnum.Planejada:
			return 'Planejado'
		case CompromissoStatusEnum.Trabalhando:
			return 'Trabalhando'
		case CompromissoStatusEnum.Finalizada:
			return 'Finalizado'
		case CompromissoStatusEnum.Cancelado:
			return 'Cancelado'
		case CompromissoStatusEnum.Ausencia:
			return 'Ausência'
		default:
			return 'Desconhecido'
	}
}

export function getAtividadeStatus(status): string {
	switch (status) {
		case AtividadeStatusEnum.PLANEJADA:
		case AtividadeStatusEnum.EM_ABERTO:
			return 'Planejado'
		case AtividadeStatusEnum.TRABALHANDO:
			return 'Trabalhando'
		case AtividadeStatusEnum.CONCLUÍDA:
			return 'Finalizado'
		case AtividadeStatusEnum.INATIVO:
			return 'Cancelado'
		default:
			return 'Desconhecido'
	}
}

export function getAtividadeTipo(tipo: AtividadeTipo): string {
	switch (tipo) {
		case AtividadeTipo.LIGACAO:
			return 'Ligação'
		case AtividadeTipo.RELACIONAMENTO:
			return 'Relacionamento'
		case AtividadeTipo.COMPROMISSO:
			return 'Compromisso'
		default:
			return 'Desconhecido'
	}
}

export function obterTituloDatasEStatusFormatadasCalendario(
	item: {
		dataInicio: string
		dataFim: string
		horaInicio: string
		horaFim: string
		nomeConta: string
		nomeEmpresa: string
		status: string
	},
	tipo: string
) {
	const timezone = new Date().getTimezoneOffset()
	const dataInicio = addHours(new Date(item.dataInicio), timezone / 60)
	let dataFim = addHours(new Date(item.dataFim), timezone / 60)
	if (!item.dataFim) {
		dataFim = addMinutes(dataInicio, 30)
	}

	const statusFormatado =
		tipo === TipoAtividadeCompromisso.COMPROMISSO ||
		tipo === EnumTipoAtividadeCalendario.COMPROMISSO
			? getCompromissoStatus(item.status)
			: getAtividadeStatus(item.status)

	const dados = {
		titulo: `
		${
			item.dataInicio
				? getHours(dataInicio).toString().padStart(2, '0') +
					':' +
					getMinutes(dataInicio).toString().padStart(2, '0')
				: '-'
		} às
			${
				item.dataFim
					? getHours(dataFim).toString().padStart(2, '0') +
						':' +
						getMinutes(dataFim).toString().padStart(2, '0')
					: item.horaFim
			} |
			${statusFormatado} |
			${item.nomeConta ? item.nomeConta + '|' : ''}  ${item.nomeEmpresa}`,
		dataInicio,
		dataFim,
		status: statusFormatado
	}

	return dados
}

export function passou48Horas(data: Date): boolean {
	const agora = new Date()
	data.setHours(data.getHours() + 3)

	const diferencaEmMilissegundos = agora.getTime() - data.getTime()

	const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60)

	return diferencaEmHoras >= 48
}

export function getErrorMessage(errorKey: string, params: any = {}): string {
	const errorMessage = ERROR_MESSAGES[errorKey]
	if (!errorMessage) {
		return ''
	}

	return errorMessage.replace(/\{\{([a-zA-Z0-9_-]+)\}\}/g, (_, key) => {
		const value = params
		if (value !== undefined) {
			return value
		} else {
			return `{{${key}}}`
		}
	})
}

export function getErrorKeys(errors: {[key: string]: any}): string[] {
	return Object.keys(errors)
}

export function getEmailUser(): string {
	const data = localStorage.getItem('remember_login')
		? JSON.parse(localStorage.getItem('remember_login'))
		: null
	return data?.con_email
}

export function formatCNPJ(cnpj: string) {
	cnpj = cnpj.replace(/[^\d]+/g, '')

	if (cnpj.length !== 14) {
		return 'Invalid CNPJ'
	}

	return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function calcularIdade(dataNascimento: Date): number {
	const hoje = new Date()
	let idade = hoje.getFullYear() - dataNascimento.getFullYear()
	const mes = hoje.getMonth() - dataNascimento.getMonth()

	if (mes < 0 || (mes === 0 && hoje.getDate() < dataNascimento.getDate())) {
		idade--
	}
	return idade
}

export function randomNumber() {
	const minNumber = 100000
	const maxNumber = 999999
	return Math.floor(Math.random() * (maxNumber + 1) + minNumber)
}
