<div *ngIf="!state.is404[0]" ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="nav-item mr-lg-3">
  <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
    <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
    <span *ngIf="notificacoesNaoLidas" class="badge badge-primary badge-dot indicator"></span>
  </a>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right custom-dropdown">
    <div class="dropdow-header">
      <h6 class="dropdown-header font-weight-bold mb-2">
        Notificações ({{ notificacoes.length }})
      </h6>
      <button class="button-lido" (click)="marcarTodasComoVisualizadas()">Marcar tudo como lido</button>
    </div>
    <div *ngIf="notificacoes.length > 0; else semNotificacoes">
      <div *ngFor="let notificacao of notificacoes; let isFirst = first" class="dropdown-item" [ngClass]="{
             'first-item': isFirst,
             'bg-danger text-white': !notificacao.visualizado
           }" (click)="marcarComoVisualizado(notificacao)">
        <span>
          <span class="status-indicator" *ngIf="!notificacao.visualizado"></span>
          {{ notificacao.titulo }}
        </span>
        <small class="text-muted d-block">{{ formatarHora(notificacao.createdAt) }}</small>
      </div>
    </div>
    <ng-template #semNotificacoes>
      <div class="dropdown-item text-center text-muted no-notifications">
        Nenhuma notificação disponível.
      </div>
    </ng-template>
  </div>
</div>