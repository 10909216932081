import {Injectable} from '@angular/core'
import {HttpClient, HttpEventType, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http'
import {Observable, of} from 'rxjs/index'
import {catchError, map, retry, timeout} from 'rxjs/operators'
import {PlatformLocation, DatePipe} from '@angular/common'
import {AppService} from '../app.service'
import {TranslateService} from '@ngx-translate/core'
import {ToastrService} from 'ngx-toastr'
import {Router} from '@angular/router'
// https://www.npmjs.com/package/@ngx-pwa/local-storage
import {StorageMap, LocalStorage} from '@ngx-pwa/local-storage'

// https://stackblitz.com/edit/ng-brazil?file=src%2Fapp%2Fapp.component.html
import * as textMaskAddons from 'text-mask-addons/dist/textMaskAddons'
import {Login} from '../model/login'
import {Dicionario} from '../model/dicionario'
import {DateFormatPipe} from 'ngx-moment'
import * as moment from 'moment-timezone'

import {environment} from '../../environments/environment'
import {AuthService} from './auth/auth.service'
import { WebSocketEvents } from './socket/interface/websocket.enum'
import { SocketService } from './socket/socket.service'
import {ContasUsuariosFiltroDTO} from '../model/contas-de-usuarios/dto/buscar-contas-usuarios.dto'
import {ContasUsuariosDetalhesDTO} from '../model/contas-de-usuarios/dto/detalhes-contas-usuarios.dto'
import {ContasUsuariosLideradosDTO} from '../model/contas-de-usuarios/dto/liderados-contas-usuarios.dto'
import {ContasUsuariosLideradosParamsDTO} from '../model/contas-de-usuarios/dto/liderados-contas-usuarios-params.dto'
import {ContaGrupo} from '../model/contas/dto/conta-grupo.dto'
import {ContaUsuarioDetalhesDto} from '../model/contas/dto/conta-usuario-detalhado.dto'
import {LideradosDto} from '../model/contas/dto/liderados-list.dto'
import { UsuariosListDto } from '../contas-de-usuarios/contas-de-usuarios-list/interfaces'

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private router: Router,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private platformLocation: PlatformLocation,
		private authService: AuthService,
		private appService: AppService,
		private http: HttpClient,
		private storage: StorageMap,
		protected localStorage: LocalStorage,
		private socketService: SocketService
	) {
		// https://stackoverflow.com/questions/49297680/angular-5-get-host-name-and-app-name-from-url
		// Example: localhost:4200
		this.hostName = (platformLocation as any).location.origin
		// Example: 4200
		this.hostPort = (platformLocation as any).location.port

		this.urlServerAPI = environment.API_URL
		this.urlServerAPINest = environment.API_URL_NEST

		// this.urlServerAPI = 'http://localhost:80/v1/'
		// this.urlServerAPINest = ' https://api.plataformavendas.agiben.com.br/api/'
		// this.urlServerAPINest = 'http://localhost:3001/api/'
	}

	// API Backend - DantAcesso

	get getRowLimit() {
		return 20
	}
	hostName: any
	hostPort: any
	urlServerAPI: any
	urlServerAPINest: any
	vernomecliente: boolean
	login: Login
	headers: any
	headersNest: any
	isRemoteServer: boolean = false
	isDev: boolean = false
	tipo: any

	dateMaskOptions = {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: textMaskAddons.createAutoCorrectedDatePipe('dd/mm/yyyy')
	}

	tapToDismiss = true
	closeButton = false
	progressBar = true
	preventDuplicates = false
	newestOnTop = false
	progressAnimation = 'decreasing'
	positionClass = 'toast-bottom-right'

	ngOnInit() {}

	public async getUser(): Promise<Login> {
		try {
			if (!this.login) {
				let data = await this.localStorage.getItem<Login>('login').toPromise()

				if (!data) {
					// usuario está sem sessão
					// Redirect to session expired
					this.router.navigate(['/login/authentication/login-v2'])
					return null
				}

				// let user = data as Login;
				this.login = new Login(data)

				// //CARREGAR EMPRESAS
				// if (this.login.conta) {
				//   if (!this.login.conta.empresas) {
				//     this.login.conta.empresas = await this.getContaEmpresas(this.login.conta.conta.pk_idconta)
				//   }
				// }

				this.setHeaders()
				this.setHeadersNest()
			}

			return this.login
		} catch (e) {
			console.log('getUser: ', e)

			// usuario está sem sessão
			// Redirect to session expired
			this.router.navigate(['/login/authentication/login-v2'])

			return null
		}
	}

	public async getHeaders(): Promise<any> {
		if (!this.login) {
			await this.getUser()
		}

		return this.headers
	}

	public async getHeadersNest(): Promise<any> {
		if (!this.login) {
			await this.getUser()
		}

		return this.headersNest
	}

	setHeaders() {
		this.headers = {
			'Content-Type': 'application/json',
			'x-access-token': this.login.token
		}
	}

	setHeadersNest() {
		this.headersNest = {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.login.token
		}
	}

	/**
	 *
	 * @param keyTranslate
	 * @param type -> 'success', 'error', 'info'
	 */
	showToast(
		keyTitleTranslate: string = null,
		keyMessageTranslate: any,
		type: string,
		positionClass = 'toast-bottom-right'
	) {
		const options = {
			tapToDismiss: this.tapToDismiss,
			closeButton: this.closeButton,
			progressBar: this.progressBar,
			progressAnimation: this.progressAnimation,
			positionClass: positionClass,
			rtl: this.appService.isRTL
		}

		// `newestOnTop` and `preventDuplicates` options must be set on global config
		this.toastrService.toastrConfig.newestOnTop = this.newestOnTop
		this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates

		this.translateService.get(keyMessageTranslate).subscribe(messageTranslated => {
			if (!keyTitleTranslate) {
				this.toastrService[type](messageTranslated, null, options)
			} else {
				this.translateService.get(keyTitleTranslate).subscribe(titleTranslated => {
					this.toastrService[type](messageTranslated, titleTranslated, options)
				})
			}
		})
	}

	errorMessageShow(data: any) {
		var codeError = null

		if (typeof data === 'string') {
			codeError = data
		} else if (
			data &&
			(data.status == 0 ||
				(data.error && data.error.response && data.error.response.message == 1000))
		) {
			// Não há conexao
			codeError = '1000'
		} else if (!data || !data.error || data.status == 500) {
			// Erro interno
			codeError = '500'
			console.error(data)
		} else if (data.error && data.error.code) {
			codeError = data.error.code.toString()
		} else if (data.error.errors) {
			// Multiplos erros encontrado na validação do form
			// Exibindo multiplas mensagens de erros
			for (let index = 0; index < data.error.errors.length; index++) {
				const element = data.error.errors[index]

				if (element.field) {
					this.showToast('error.info', element.field.toString(), 'info', 'toast-top-right')
				} else {
					this.showToast('error.info', element.code.toString(), 'info', 'toast-top-right')
				}
			}
		}

		if (codeError) {
			this.showToast(null, codeError, 'error', 'toast-top-right')
		}
	}

	//#region CONTAS

	loadContas(body: any) {}

	checkConta(body: any) {}

	async createContaUsuario(body): Promise<void> {
		try {
			const urlAPI = this.urlServerAPINest + 'contas/'
			await this.http.post(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createContaUsuario')
		}
	}

	async updateContaUsuario(body): Promise<void> {
		try {
			const urlAPI = this.urlServerAPINest + 'contas/' + body.pk_idconta
			await this.http.put(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateContaUsuario')
		}
	}

	/**
	 * Contas de Usuário
	 * @param operation
	 * @param result
	 */
	async createConta(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'contas/'

			this.checkConta(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createConta')
		}

		return retorno
	}

	async updateConta(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'contas/' + body.pk_idconta

			this.checkConta(body)

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateConta')
		}

		return retorno
	}

	async patchConta(id: any, body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'contas/' + id

			this.checkConta(body)

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchConta')
		}

		return retorno
	}

	async removeConta(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'contas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeConta')
		}

		return retorno
	}

	async getContaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'contas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]

			data.con_datacadastro = this.dateIsoToString(data.con_datacadastro)
		} catch (error) {
			this.handleError(error, 'getContaPorID')
		}

		return retorno
	}

	async getContaPorEmail(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				con_email: params.con_email
			}

			const urlAPI = this.urlServerAPI + 'contas/ja_existe'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getContas')
		}

		return retorno
	}

	async getContas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idconta: this.login.idConta, // hierarquia
				idgrupo: params.grupo, // id grupo
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'contas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getContas')
		}

		return retorno
	}

	async loginConta(loginPayload): Promise<any> {
		var retorno = null

		try {
			var body = loginPayload

			const urlAPI = this.urlServerAPI + 'contas/login'
			retorno = await this.http.post<any>(urlAPI, body).toPromise()

			this.loadContas(retorno)

			this.login = new Login(retorno)
			this.authService.setLogin(this.login)

			this.setHeaders()
			this.setHeadersNest()

			this.setStorageLocal('login', retorno)
		} catch (error) {
			this.handleError(error, 'loginConta')
		}

		return retorno
	}

	async recuperarSenha(loginPayload): Promise<any> {
		var retorno = null

		try {
			const headers = {
				'Content-Type': 'application/json'
			}

			var body = {
				con_email: loginPayload.con_email
			}

			const urlAPI = this.urlServerAPI + 'contas/recuperar_senha'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'recuperarSenha')
		}

		return retorno
	}

	async enviarConvite(loginPayload): Promise<any> {
		var retorno = null

		try {
			var body = {
				con_email: loginPayload.con_email
			}

			const urlAPI = this.urlServerAPI + 'contas/enviar_convite'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'enviarConvite')
		}

		return retorno
	}

	async logout(isClearSession: boolean = true) {
		try {
			var user = await this.getUser()

			this.socketService.emit(WebSocketEvents.DISCONNECT, user.idConta)

			user.sessionExpired = true

			// Registrando a saída do usuario do sistema em banco de dados
			this.createLogAcesso(user)
		} catch (error) {
			console.log('logout: ' + error)
		} finally {
			if (isClearSession == true) {
				// Limpando todos os dados salvos localmente
				this.clearStorageLocal()

				this.login = null
				this.authService.setLogin(this.login)

				this.router.navigate(['/login/authentication/login-v2'])
			}
		}
	}

	/**
	 * Buscar contas por e-mail - No login
	 * @param operation
	 * @param result
	 */

	async getContasPorEmail(params: any): Promise<any> {
		var retorno = null

		try {
			let headers = {
				'Content-Type': 'application/json'
			}

			var body = {
				pesq: params.con_email, // Nome ou Email VARCHAR,
				hierarquia: 0, // hierarquia INT,
				idgrupo: 0, // idgrupo INT,
				status: 'A', // status CHAR,
				max_limit: params.max_limit ? params.max_limit : this.getRowLimit, // max_limit INT,
				pos_offset: 0, // pos_offset INT,
				orderby: 'con_email', // orderby VARCHAR,
				direction: 'ASC' // direction VARCHAR
			}

			const urlAPI = this.urlServerAPI + 'contas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getContasPorEmail')
		}

		return retorno
	}

	async sendEmail(bodyEmail): Promise<any> {
		var retorno = null

		try {
			// const headers = {
			//   'Content-Type': 'application/json'
			// }

			const urlAPI = this.urlServerAPI + 'email/send'

			await this.http.post<any>(urlAPI, bodyEmail).toPromise()

			// 204 - No Content
			retorno = {}
		} catch (error) {
			this.handleError(error, 'sendEmail')
		}

		return retorno
	}

	async createLogAcesso(user: Login): Promise<any> {
		var retorno = null

		try {
			// O registro de log nao pode depender de token, pois a sessao pode ter sido expirada. Entao como fica o registro do log de saída de sistema sem token valido?

			var body = {
				fk_idconta: user.conta.pk_idconta,
				log_entradasaida: 'S'
			}

			const urlAPI = this.urlServerAPI + 'logacessos'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {}

		return retorno
	}

	//#endregion

	//#region Cliente
	checkCliente(body: any) {
		body.cli_cpf_cnpj = this.getOnlyNumbers(body.cli_cpf_cnpj)
		body.cli_cep = this.getOnlyNumbers(body.cli_cep)
	}

	loadCliente(data: any) {
		/*data.forEach(o => {
      o.dependentes = this.stringToJSON(o.dependentes);
    });/**/
	}

	/**
	 * Clientes
	 * @param operation
	 * @param result
	 */

	async createCliente(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'clientes/'

			this.checkCliente(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createCliente')
		}

		return retorno
	}

	async patchCliente(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'clientes/' + id

			this.checkCliente(body)

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchCliente')
		}

		return retorno
	}

	async removeCliente(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'clientes/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeCliente')
		}

		return retorno
	}

	async getClientePorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'clientes/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadCliente(retorno)
		} catch (error) {
			this.handleError(error, 'getClientePorID')
		}

		return retorno
	}

	async getClientes(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				fk_idtitular: params.fk_idtitular,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'clientes/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getClientes')
		}

		return retorno
	}

	//#endregion

	//#region TELEFONE

	checkTelefone(body: any) {}

	/**
	 * TELEFONE - Cartões, Senhas, TELEFONE
	 * @param operation
	 * @param result
	 */

	async createTelefone(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'telefones/'

			this.checkTelefone(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createTelefone')
		}

		return retorno
	}

	async updateTelefone(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'telefones/' + body.pk_idtelefone

			this.checkTelefone(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateTelefone')
		}

		return retorno
	}

	async removeTelefone(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'telefones/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeTelefone')
		}

		return retorno
	}

	async getTelefonePorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'telefones/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getTelefonePorID')
		}

		return retorno
	}

	async getTelefonesPorIDUsuario(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'view_telefones',
				params.idusuario,
				params.searchQuery,
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy,
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getTelefonesPorIDUsuario')
		}

		return retorno
	}

	//#endregion

	//#region PLANO
	checkPlano(body: any) {}

	loadPlano(data: any) {
		data.forEach(o => {
			o.plano_regioes_json = this.stringToJSON(o.plano_regioes_json)
		})
	}

	async patchPlano(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'planos/' + body.pk_idplano

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchPlano')
		}

		return retorno
	}

	async removePlano(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'planos/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removePlano')
		}

		return retorno
	}

	async getPlanoPorID(id: number) {
		var retorno = null

		try {
			const urlAPI = `${this.urlServerAPI}planos/${id}`
			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadPlano(retorno)
		} catch (error) {
			this.handleError(error, 'getPlanoPorID')
		}

		return retorno
	}

	async getPlanos(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idclassificacao: params.fk_idclassificacao,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'planos/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getPlanos')
		}

		return retorno
	}
	//#endregion

	//#region PLANO REGIOES

	checkPlanoRegioes(body: any) {}

	/**
	 * PlanoRegioes
	 * @param operation
	 * @param result
	 */

	async createPlanoRegiao(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_regioes/'

			this.checkPlanoRegioes(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createPlanoRegiao')
		}

		return retorno
	}

	async updatePlanoRegiao(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_regioes/' + id

			this.checkPlanoRegioes(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updatePlanoRegiao')
		}

		return retorno
	}

	async patchPlanoRegiao(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_regioes/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchPlanoRegiao')
		}

		return retorno
	}

	async removePlanoRegiao(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_regioes/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removePlanoRegiao')
		}

		return retorno
	}

	async getPlanoRegiaoPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_regioes/' + id
			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getPlanoRegioesPorID')
		}

		return retorno
	}

	async getPlanoRegioes(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idRegiao: params.idRegiao,
				idFormaPagamento: params.idFormaPagamento,
				idplano: params.idplano,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'plano_regioes/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getPlanoRegioes')
		}

		return retorno
	}

	//#endregion

	//#region CAMPANHA
	checkCampanha(body: any) {}

	loadCampanha(data: any) {
		data.forEach(o => {
			// o.plano_campanhas_json = this.stringToJSON(o.plano_campanhas_json);
			// o.conta_campanhas_json = this.stringToJSON(o.conta_campanhas_json);
			// o.regiao_campanhas_json = this.stringToJSON(o.regiao_campanhas_json);
		})
	}

	/**
	 * Campanhas
	 * @param operation
	 * @param result
	 */

	async createCampanha(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'campanhas/'

			this.checkCampanha(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createCampanha')
		}

		return retorno
	}

	async updateCampanha(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'campanhas/' + body.pk_idcampanha

			this.checkCampanha(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateCampanha')
		}

		return retorno
	}

	async patchCampanha(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'campanhas/' + body.pk_idcampanha

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchCampanha')
		}

		return retorno
	}

	async removeCampanha(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'campanhas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeCampanha')
		}

		return retorno
	}

	async getCampanhaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'campanhas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadCampanha(retorno)
		} catch (error) {
			this.handleError(error, 'getCampanhaPorID')
		}

		return retorno
	}

	async getCampanhas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idempresa: params.fk_idempresa,
				idconta: params.fk_idconta,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'campanhas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getCampanhas')
		}

		return retorno
	}

	async getCampanhasAtivasEDisponiveis(): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'campanhas/filter/ativas_e_disponiveis'

			retorno = await this.http.post<any>(urlAPI, null).toPromise()
		} catch (error) {
			this.handleError(error, 'getCampanhasAtivasEDisponiveis')
		}

		return retorno
	}
	//#endregion

	//#region CAMPANHA
	checkVenda(body: any) {}

	loadVenda(data: any) {
		data.forEach(o => {
			// o.plano_vendas_json = this.stringToJSON(o.plano_vendas_json);
			// o.conta_vendas_json = this.stringToJSON(o.conta_vendas_json);
			// o.regiao_vendas_json = this.stringToJSON(o.regiao_vendas_json);
		})
	}

	/**
	 * Vendas
	 * @param operation
	 * @param result
	 */

	async createVenda(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/'

			this.checkVenda(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createVenda')
		}

		return retorno
	}

	async inserirVendaS4_Cartao(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/s4/cartao/inserirvenda'

			this.checkVenda(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'inserirvendas4_cartao')
		}

		return retorno
	}

	async inserirVendaS4_Boleto(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/s4/boleto/inserirvenda'

			this.checkVenda(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'inserirvendas4_boleto')
		}

		return retorno
	}

	async updateVenda(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/' + body.pk_idvenda

			this.checkVenda(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateVenda')
		}

		return retorno
	}

	async patchVenda(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/' + body.pk_idvenda

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchVenda')
		}

		return retorno
	}

	async removeVenda(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeVenda')
		}

		return retorno
	}

	async getVendaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadVenda(retorno)
		} catch (error) {
			this.handleError(error, 'getVendaPorID')
		}

		return retorno
	}

	async getVendaPorID_S4(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/' + id + '/s4'

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadVenda(retorno)
		} catch (error) {
			this.handleError(error, 'getVendaPorID_S4')
		}

		return retorno
	}

	async getVendas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'vendas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getVendas')
		}

		return retorno
	}

	async getVendasAtivasEDisponiveis(): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'vendas/filter/ativas_e_disponiveis'

			retorno = await this.http.post<any>(urlAPI, null).toPromise()
		} catch (error) {
			this.handleError(error, 'getVendasAtivasEDisponiveis')
		}

		return retorno
	}
	//#endregion

	//#region PLANO CAMPANHA

	checkPlanoCampanhas(body: any) {}

	/**
	 * PlanoCampanhas
	 * @param operation
	 * @param result
	 */

	async createPlanoCampanha(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_campanhas/'

			this.checkPlanoCampanhas(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createPlanoCampanha')
		}

		return retorno
	}

	async updatePlanoCampanha(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_campanhas/' + id

			this.checkPlanoCampanhas(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updatePlanoCampanha')
		}

		return retorno
	}

	async patchPlanoCampanha(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_campanhas/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchPlanoCampanha')
		}

		return retorno
	}

	async removePlanoCampanha(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_campanhas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removePlanoCampanha')
		}

		return retorno
	}

	async getPlanoCampanhaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plano_campanhas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getPlanoCampanhasPorID')
		}

		return retorno
	}

	async getPlanoCampanhas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idcampanha: params.fk_idcampanha,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'plano_campanhas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getPlanoCampanhas')
		}

		return retorno
	}

	//#endregion

	//#region COMISSAO

	checkComissoes(body: any) {}

	/**
	 * Comissoes
	 * @param operation
	 * @param result
	 */

	async createComissao(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'comissoes/'

			this.checkComissoes(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createComissao')
		}

		return retorno
	}

	async updateComissao(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'comissoes/' + id

			this.checkComissoes(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateComissao')
		}

		return retorno
	}

	async patchComissao(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'comissoes/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchComissao')
		}

		return retorno
	}

	async removeComissao(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'comissoes/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeComissao')
		}

		return retorno
	}

	async getComissaoPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'comissoes/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getComissoesPorID')
		}

		return retorno
	}

	async getComissoes(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idconta: params.fk_idconta,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'comissoes/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getComissoes')
		}

		return retorno
	}

	//#endregion

	//#region CONTA CAMPANHA

	checkContaCampanhas(body: any) {}

	/**
	 * ContaCampanhas
	 * @param operation
	 * @param result
	 */

	async createContaCampanha(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'conta_campanhas/'

			this.checkContaCampanhas(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createContaCampanha')
		}

		return retorno
	}

	async updateContaCampanha(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'conta_campanhas/' + id

			this.checkContaCampanhas(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateContaCampanha')
		}

		return retorno
	}

	async patchContaCampanha(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'conta_campanhas/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchContaCampanha')
		}

		return retorno
	}

	async removeContaCampanha(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'conta_campanhas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeContaCampanha')
		}

		return retorno
	}

	async getContaCampanhaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'conta_campanhas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getContaCampanhasPorID')
		}

		return retorno
	}

	async getContaCampanhas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idcampanha: params.fk_idcampanha,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'conta_campanhas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getContaCampanhas')
		}

		return retorno
	}

	//#endregion

	//#region REGIAO CAMPANHA

	checkRegiaoCampanhas(body: any) {}

	/**
	 * RegiaoCampanhas
	 * @param operation
	 * @param result
	 */

	async createRegiaoCampanha(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regiao_campanhas/'

			this.checkRegiaoCampanhas(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createRegiaoCampanha')
		}

		return retorno
	}

	async updateRegiaoCampanha(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regiao_campanhas/' + id

			this.checkRegiaoCampanhas(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateRegiaoCampanha')
		}

		return retorno
	}

	async patchRegiaoCampanha(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regiao_campanhas/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchRegiaoCampanha')
		}

		return retorno
	}

	async removeRegiaoCampanha(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regiao_campanhas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeRegiaoCampanha')
		}

		return retorno
	}

	async getRegiaoCampanhaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regiao_campanhas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getRegiaoCampanhasPorID')
		}

		return retorno
	}

	async getRegiaoCampanhas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idcampanha: params.fk_idcampanha,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'regiao_campanhas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRegiaoCampanhas')
		}

		return retorno
	}

	//#endregion

	//#region EMPRESA
	async loadEmpresa(body: any) {}

	checkEmpresa(body: any) {}

	/**
	 * Empresas
	 * @param operation
	 * @param result
	 */

	async createEmpresa(body: any): Promise<any> {
		var retorno = null
		// const maxRetries = 3;

		try {
			//

			const urlAPI = this.urlServerAPI + 'empresas/'

			this.checkEmpresa(body)

			// retorno = await this.http.post<any>(urlAPI, body).pipe(retry(maxRetries)).toPromise();
			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createEmpresa')
		}

		return retorno
	}

	async updateEmpresa(body: any, id): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'empresas/' + id

			this.checkEmpresa(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateEmpresa')
		}

		return retorno
	}

	async patchEmpresa(body: any, id): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'empresas/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchEmpresa')
		}

		return retorno
	}

	async removeEmpresa(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'empresas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeEmpresa')
		}

		return retorno
	}

	async getEmpresaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'empresas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getEmpresaPorID')
		}

		return retorno
	}

	async getEmpresas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				tipo: params.tipo,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: 100, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC',
				list_id_empresa: params.list_id_empresa
			}

			const urlAPI = this.urlServerAPI + 'empresas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getEmpresas')
		}

		return retorno
	}
	//#endregion

	//#region CONTA_EMPRESA

	async createContaEmpresa(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'conta_empresas/'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createContaEmpresa')
		}

		return retorno
	}

	async removeContaEmpresa(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'conta_empresas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeContaEmpresa')
		}

		return retorno
	}

	async getContaEmpresaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'conta_empresas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getContaEmpresaPorID')
		}

		return retorno
	}

	async getContaEmpresas(idConta: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPINest + `conta_empresas/${idConta}/busca_por_id_conta`

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getContaEmpresas')
		}

		return retorno
	}
	//#endregion

	//#region MODELO DE EMAIL MARKETING
	async loadModeloemailmarketing(body: any) {}

	checkModeloemailmarketing(body: any) {}

	/**
	 * Modeloemailmarketings
	 * @param operation
	 * @param result
	 */

	async createModeloemailmarketing(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'modeloemailmarketing/'

			this.checkModeloemailmarketing(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createModeloemailmarketing')
		}

		return retorno
	}

	async updateModeloemailmarketing(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'modeloemailmarketing/' + body.pk_idmodeloemailmarketing

			this.checkModeloemailmarketing(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateModeloemailmarketing')
		}

		return retorno
	}

	async patchModeloemailmarketing(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'modeloemailmarketing/' + body.pk_idmodeloemailmarketing

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchModeloemailmarketing')
		}

		return retorno
	}

	async removeModeloemailmarketing(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'modeloemailmarketing/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeModeloemailmarketing')
		}

		return retorno
	}

	async getModeloemailmarketingPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'modeloemailmarketing/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getModeloemailmarketingPorID')
		}

		return retorno
	}

	async getModeloemailmarketing(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idgrupo: params.fk_idgrupo,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'modeloemailmarketing/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getModeloemailmarketing')
		}

		return retorno
	}
	//#endregion

	//#region REGIAO
	checkRegiao(body: any) {}

	/**
	 * Regioes
	 * @param operation
	 * @param result
	 */

	async createRegiao(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regioes/'

			this.checkRegiao(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createRegiao')
		}

		return retorno
	}

	async updateRegiao(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regioes/' + body.pk_idregiao

			this.checkRegiao(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateRegiao')
		}

		return retorno
	}

	async patchRegiao(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regioes/' + body.pk_idregiao

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchRegiao')
		}

		return retorno
	}

	async removeRegiao(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regioes/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeRegiao')
		}

		return retorno
	}

	async getRegiaoPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'regioes/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getRegiaoPorID')
		}

		return retorno
	}

	async getRegioes(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idestado: params.idestado,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'regioes/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRegioes')
		}

		return retorno
	}
	//#endregion

	//#region HISTORICO_TRATATICA
	checkHistoricoTratativa(body: any) {}

	/**
	 * Regioes
	 * @param operation
	 * @param result
	 */

	async createHistoricoTratativa(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'historico_tratativas/'

			this.checkHistoricoTratativa(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createHistoricoTratativa')
		}

		return retorno
	}

	async updateHistoricoTratativa(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'historico_tratativas/' + body.pk_idhistoricotratativa

			this.checkHistoricoTratativa(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateHistoricoTratativa')
		}

		return retorno
	}

	async patchHistoricoTratativa(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'historico_tratativas/' + body.pk_idhistoricotratativa

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchHistoricoTratativa')
		}

		return retorno
	}

	async removeHistoricoTratativa(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'historico_tratativas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeHistoricoTratativa')
		}

		return retorno
	}

	async getHistoricoTratativaPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'historico_tratativas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getHistoricoTratativaPorID')
		}

		return retorno
	}

	async getHistoricoTrativas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idcliente: params.idcliente,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'historico_tratativas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRegioes')
		}

		return retorno
	}
	//#endregion

	//#region OPERADORA
	checkOperadora(body: any) {}

	/**
	 * Operadoras de Telefonia
	 * @param operation
	 * @param result
	 */

	async createOperadora(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'operadoras/'

			this.checkOperadora(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createOperadora')
		}

		return retorno
	}

	async updateOperadora(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'operadoras/' + body.pk_idoperadora

			this.checkOperadora(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateOperadora')
		}

		return retorno
	}

	async patchOperadora(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'operadoras/' + body.pk_idoperadora

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchOperadora')
		}

		return retorno
	}

	async removeOperadora(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'operadoras/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeOperadora')
		}

		return retorno
	}

	async getOperadoraPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'operadoras/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getOperadoraPorID')
		}

		return retorno
	}

	async getOperadoras(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'view_operadoras',
				params.searchQuery,
				!params.status || params.status.length == 2 ? null : params.status[0],
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy,
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getOperadoras')
		}

		return retorno
	}
	//#endregion

	//#region TIPO_TELEFONES
	async getTipoTelefones(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'view_tipotelefones',
				params.searchQuery,
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy,
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getTipoTelefones')
		}

		return retorno
	}
	//#endregion

	//#region GRUPOS

	checkGrupos(body: any) {}

	/**
	 * Grupos de Usuário
	 * @param operation
	 * @param result
	 */

	async createGrupo(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupos/'

			this.checkGrupos(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createGrupo')
		}

		return retorno
	}

	async updateGrupo(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupos/' + id

			this.checkGrupos(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateGrupo')
		}

		return retorno
	}

	async patchGrupo(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupos/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchGrupo')
		}

		return retorno
	}

	async removeGrupo(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupos/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeGrupo')
		}

		return retorno
	}

	async getGrupoPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupos/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getGruposPorID')
		}

		return retorno
	}

	async getGrupos(params: any): Promise<any> {
		var retorno = null

		if (!this.login) {
			await this.getUser()
		}

		try {
			var body = {
				pesq: params.searchQuery,
				hierarquia: this.login.conta.gru_hierarquia, // hierarquia
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'grupos/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getGrupos')
		}

		return retorno
	}

	async getGruposAgiSales(): Promise<ContaGrupo[]> {
		try {
			const urlAPI = this.urlServerAPINest + 'contas/grupos/filter'
			return await this.http.post<ContaGrupo[]>(urlAPI, {}).toPromise()
		} catch (error) {
			this.handleError(error, 'getGruposAgisales')
		}
	}

	async getLideresPorGrupo(groupId: number): Promise<ContaUsuarioDetalhesDto[]> {
		try {
			const urlAPI = this.urlServerAPINest + `contas/lideres-por-grupo/${groupId}`
			return await this.http.get<ContaUsuarioDetalhesDto[]>(urlAPI, {}).toPromise()
		} catch (error) {
			this.handleError(error, 'getGruposAgisales')
		}
	}

	async getContasUsuariosAgiSales(body: ContasUsuariosFiltroDTO): Promise<UsuariosListDto> {
		try {
			const urlAPI = this.urlServerAPINest + 'contas/filter'
			return await this.http.post<UsuariosListDto>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getContasUsuariosAgisales')
		}
	}

	async getContasParaLiderar(idConta: number, search?: string): Promise<LideradosDto[]> {
		try {
			const urlAPI = this.urlServerAPINest + `contas/contas-para-liderar/${idConta}${search ? '/?search=' + search : ''}`
			return await this.http.get<LideradosDto[]>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getPossiveisLiderados')
		}
	}

	async atualizarLideradosEmLotePorConta(idConta: number, lideradosId: number[]): Promise<void> {
		try {
			const urlAPI = this.urlServerAPINest + `contas/atualiza-liderados-lote/${idConta}`
			await this.http.post<void>(urlAPI, lideradosId).toPromise()
		} catch (error) {
			this.handleError(error, 'getPossiveisLiderados')
		}
	}

	//#endregion

	//#region CENTRAL_RELATORIOS

	//#region MENSAGEM

	checkMensagens(body: any) {
		if (body.cre_grupospermitidos) {
			body.cre_grupospermitidos = body.cre_grupospermitidos.toString() // array.toString(); // '1,2,3'
		}

		if (body.cre_parametros) {
			body.cre_parametros = body.cre_parametros.toString() // array.toString(); // '1,2,3'
		}

		if (body.fk_idconta) {
			body.fk_idconta = body.fk_idconta.toString() // array.toString(); // '1,2,3'
		}
	}

	loadMensagens(body: any) {
		let o = body[0]

		if (o.cre_grupospermitidos) {
			// convert to array of int
			o.cre_grupospermitidos = o.cre_grupospermitidos.split(',').map(function (item) {
				return parseInt(item, 10)
			})
		}

		if (o.cre_parametros) {
			// convert to array of int
			o.cre_parametros = o.cre_parametros.split(',')
		}
	}

	/**
	 * Mensagens
	 * @param operation
	 * @param result
	 */

	async createMensagem(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'mensagens/'

			this.checkMensagens(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createMensagem')
		}

		return retorno
	}

	async updateMensagem(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'mensagens/' + id

			this.checkMensagens(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateMensagem')
		}

		return retorno
	}

	async patchMensagem(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'mensagens/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchMensagem')
		}

		return retorno
	}

	async removeMensagem(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'mensagens/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeMensagem')
		}

		return retorno
	}

	async getMensagemPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'mensagens/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadMensagens(retorno)
		} catch (error) {
			this.handleError(error, 'getMensagensPorID')
		}

		return retorno
	}

	async getMensagens(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery || null,
				vencidas: params.vencidas || false,
				idorigem: params.idorigem || null,
				iddestino: params.iddestino || null,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'mensagens/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getMensagens')
		}

		return retorno
	}
	//#endregion

	checkCentralRelatorios(body: any) {
		if (body.cre_grupospermitidos) {
			body.cre_grupospermitidos = body.cre_grupospermitidos.toString() // array.toString(); // '1,2,3'
		}

		if (body.cre_parametros) {
			body.cre_parametros = body.cre_parametros.toString() // array.toString(); // '1,2,3'
		}

		if (body.fk_idconta) {
			body.fk_idconta = body.fk_idconta.toString() // array.toString(); // '1,2,3'
		}
	}

	loadCentralRelatorios(body: any) {
		let o = body[0]

		if (o.cre_grupospermitidos) {
			// convert to array of int
			o.cre_grupospermitidos = o.cre_grupospermitidos.split('.').map(function (item) {
				return parseInt(item, 10)
			})
		}

		if (o.cre_parametros) {
			// convert to array of int
			o.cre_parametros = o.cre_parametros.split(',')
		}
	}

	/**
	 * CentralRelatorios
	 * @param operation
	 * @param result
	 */

	async createCentralRelatorio(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'central_relatorios/'

			this.checkCentralRelatorios(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createCentralRelatorio')
		}

		return retorno
	}

	async updateCentralRelatorio(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'central_relatorios/' + id

			this.checkCentralRelatorios(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateCentralRelatorio')
		}

		return retorno
	}

	async patchCentralRelatorio(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'central_relatorios/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchCentralRelatorio')
		}

		return retorno
	}

	async removeCentralRelatorio(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'central_relatorios/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeCentralRelatorio')
		}

		return retorno
	}

	async getCentralRelatorioPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'central_relatorios/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadCentralRelatorios(retorno)
		} catch (error) {
			this.handleError(error, 'getCentralRelatoriosPorID')
		}

		return retorno
	}

	async getCentralRelatorios(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				hierarquia: this.login.idConta,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'central_relatorios/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getCentralRelatorios')
		}

		return retorno
	}
	//#endregion

	//#region FORMA DE PAGAMENTOS
	checkFormaPagamento(body: any) {}

	/**
	 * FormasPagamentos
	 * @param operation
	 * @param result
	 */

	async createFormaPagamento(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'formas_pagamentos/'

			this.checkFormaPagamento(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createFormaPagamento')
		}

		return retorno
	}

	async updateFormaPagamento(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'formas_pagamentos/' + body.pk_idformapagamento

			this.checkFormaPagamento(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateFormaPagamento')
		}

		return retorno
	}

	async patchFormaPagamento(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'formas_pagamentos/' + body.pk_idformapagamento

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchFormaPagamento')
		}

		return retorno
	}

	async removeFormaPagamento(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'formas_pagamentos/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeFormaPagamento')
		}

		return retorno
	}

	async getFormaPagamentoPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'formas_pagamentos/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getFormaPagamentoPorID')
		}

		return retorno
	}

	async getFormasPagamentos(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'formas_pagamentos/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getFormasPagamentos')
		}

		return retorno
	}
	//#endregion

	async getClassificacoes(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'classificacoes/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getClassificacoes')
		}

		return retorno
	}

	//#region CONFIGURACOES

	async hasServico() {
		var user = await this.getUser()

		return (
			(user && user.configuracao_atual.visitante.mostrar_servicos) ||
			user.configuracao_atual.morador.mostrar_servicos ||
			user.configuracao_atual.funcionario.mostrar_servicos ||
			user.configuracao_atual.aluno.mostrar_servicos
		)
	}

	checkConfiguracoes(body: any) {}

	async loadConfiguracoes(body: any) {
		body.forEach(o => {
			try {
				o.csi_configuracao = this.stringToJSON(o.csi_configuracao)
			} catch (error) {
				console.log(error)
			}
		})
	}

	/**
	 * Configuracoes de Usuário
	 * @param operation
	 * @param result
	 */

	async createConfiguracoes(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'configuracaosistemas/'

			this.checkConfiguracoes(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createConfiguracoes')
		}

		return retorno
	}

	async updateConfiguracoes(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'configuracaosistemas/' + id

			this.checkConfiguracoes(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateConfiguracoes')
		}

		return retorno
	}

	async patchConfiguracoes(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'configuracaosistemas/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchConfiguracoes')
		}

		return retorno
	}

	async removeConfiguracoes(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'configuracaosistemas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeConfiguracoes')
		}

		return retorno
	}

	async getConfiguracoesPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'configuracaosistemas/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			this.loadConfiguracoes(retorno.response)
		} catch (error) {
			this.handleError(error, 'getConfiguracoesPorID')
		}

		return retorno
	}

	async getConfiguracaoPorIDCliente(params: any = null): Promise<any> {
		var retorno = null

		try {
			var body = ['view_configuracoes']

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()

			this.loadConfiguracoes(retorno.response)
		} catch (error) {
			this.handleError(error, 'getConfiguracaoPorIDCliente')
		}

		return retorno
	}

	//#endregion

	//#region GRUPO_PERMISSAO

	checkGrupoPermissoes(body: any) {}

	loadGrupoPermissoes(body: any) {
		let retorno = null

		if (body) {
			let secoes = []
			let secao_atual = null

			body.forEach(o => {
				try {
					// Organizando os dicionarios dentro das secoes
					// Exemplo de retorno do SQL:
					// pk_iddicionario, dic_nome,           pk_idsecao, sec_nome,   grupos_permissoes_json
					// 2	            EMPRESA VISITANTE	3	        Cadastros	[{"pk_idgrupo_permissao":1019,"gpe_acesso":"R"}]

					if (secao_atual == null || secao_atual.pk_idsecao != o.pk_idsecao) {
						secao_atual = {
							pk_idsecao: o.pk_idsecao,
							sec_nome: o.sec_nome,
							dicionarios: []
						}

						// Adiciona mais uma secao a lista
						secoes.push(secao_atual)
					}

					var grupos_permissoes_json = {
						pk_idgrupopermissao: null,
						gpe_acesso: null,
						marcartodos: false
					}

					if (o.grupos_permissoes_json) {
						grupos_permissoes_json = this.stringToJSON(o.grupos_permissoes_json)

						grupos_permissoes_json = grupos_permissoes_json[0]

						if (grupos_permissoes_json.gpe_acesso) {
							grupos_permissoes_json.gpe_acesso = grupos_permissoes_json.gpe_acesso.split('')
							grupos_permissoes_json.marcartodos = grupos_permissoes_json.gpe_acesso.length == 4
						}
					}

					// Adicionando cada dicionario a sua secao
					secao_atual.dicionarios.push({
						pk_iddicionariotabela: o.pk_iddicionariotabela,
						dic_nome: o.dic_nome,
						permissao: grupos_permissoes_json
					})
				} catch (error) {
					console.log('loadGrupoPermissoes: ' + error)
				}
			})

			retorno = secoes
		}

		return retorno
	}

	/**
	 * GrupoPermissoess de Usuário
	 * @param operation
	 * @param result
	 */

	async createGrupoPermissoes(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupopermissoes/'

			this.checkGrupoPermissoes(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createGrupoPermissoes')
		}

		return retorno
	}

	async updateGrupoPermissoes(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupopermissoes/' + id

			this.checkGrupoPermissoes(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateGrupoPermissoes')
		}

		return retorno
	}

	async patchGrupoPermissoes(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupopermissoes/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchGrupoPermissoes')
		}

		return retorno
	}

	async removeGrupoPermissoes(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupopermissoes/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeGrupoPermissoes')
		}

		return retorno
	}

	async getGrupoPermissoesPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'grupopermissoes/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getGrupoPermissoesPorID')
		}

		return retorno
	}

	async getGrupoPermissoesPorIDGrupo(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				fk_idgrupo: params.fk_idgrupo
			}

			const urlAPI = this.urlServerAPI + 'grupopermissoes/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()

			retorno = this.loadGrupoPermissoes(retorno)
		} catch (error) {
			this.handleError(error, 'getGrupoPermissoesPorIDGrupo')
		}

		return retorno
	}

	//#endregion

	//#region MEUS RELATORIOS

	async getRelatorios(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				idconta: this.login.idConta, // hierarquia
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'central_relatorios/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRelatorios')
		}

		return retorno
	}

	//#endregion

	//#region PLATAFORMA

	async getByPlataformaPorCodigoS4(params: any): Promise<any> {
		var retorno = null
		try {
			const urlAPI = `${this.urlServerAPI}contas/getCodigoPlataforma`
			retorno = await this.http.post<any>(urlAPI, params).toPromise()
		} catch (error) {
			this.handleError(error, 'getByPlataformaPorCodigoS4')
		}

		return retorno
	}

	async getPlataformas(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				pesq: params.searchQuery,
				status: !params.status || params.status.length == 2 ? null : params.status[0],
				max_limit: this.getRowLimit, // max_limit INT,
				pos_offset: params.offset,
				orderby: params.sortBy,
				direction: params.sortDesc ? 'DESC' : 'ASC'
			}

			const urlAPI = this.urlServerAPI + 'plataformas/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getPlataformas')
		}

		return retorno
	}

	//#endregion

	//#region DICIONARIO

	hasDic(dicEnum: any) {
		let retorno: boolean = false

		if (this.login) {
			// https://stackoverflow.com/questions/4775722/how-to-check-if-an-object-is-an-array?rq=1
			// TODO - voltar caso de problema
			// if (Object.prototype.toString.call(dicEnum) === "[object Array]") {
			if (Array.isArray(dicEnum)) {
				for (let index = 0; index < dicEnum.length; index++) {
					const o = dicEnum[index]

					if (this.login.temPermissao(o, 'R')) {
						retorno = true
						break
					}
				}
			} else {
				retorno = this.login.temPermissao(dicEnum, 'R')
			}
		}

		return retorno
	}

	checkDicionarios(body: any) {}

	/**
	 * Dicionarioss de Usuário
	 * @param operation
	 * @param result
	 */

	async createDicionario(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'dicionarios/'

			this.checkDicionarios(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createDicionario')
		}

		return retorno
	}

	async updateDicionario(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'dicionarios/' + id

			this.checkDicionarios(body)

			retorno = await this.http.put<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateDicionario')
		}

		return retorno
	}

	async patchDicionario(body: any, id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'dicionarios/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'patchDicionario')
		}

		return retorno
	}

	async removeDicionario(id: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'dicionarios/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeDicionario')
		}

		return retorno
	}

	async getDicionarioPorID(id: number): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'dicionarios/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getDicionarioPorID')
		}

		return retorno
	}

	async getDicionarios(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'view_dicionarios',
				params.searchQuery,
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy,
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getDicionarios')
		}

		return retorno
	}
	//#endregion

	//#region  Auditoria

	async getAuditoriaPorDicionarioEId(params: any): Promise<any> {
		var retorno = null

		try {
			var body = {
				fk_iddicionariotabela: params.fk_iddicionariotabela,
				aud_idtabela: params.aud_idtabela,
				max_limit: params.max_limit ? params.max_limit : this.getRowLimit, // max_limit INT,
				pos_offset: 0, // pos_offset INT,
				orderby: 'con_email', // orderby VARCHAR,
				direction: 'ASC' // direction VARCHAR
			}

			const urlAPI = this.urlServerAPI + 'auditorias/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getAuditoriaPorDicionarioEId')
		}

		return retorno
	}
	//#endregion

	//#region s4e CLI

	async execSQL_S4(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 's4e/exec_sql'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'execSQL_S4')
		}

		return retorno
	}

	//#endregion

	//#region PLATAFORMA RH

	async execSQL_PLATAFORMA_RH(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'plataforma_rh/exec_sql'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'execSQL_PLATAFORMA_RH')
		}

		return retorno
	}

	//#endregion

	//#region s4e AGIBEN

	async execSQL_S4_AGIBEN(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 's4_eagiben/exec_sql'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'execSQL_S4_AGIBEN')
		}

		return retorno
	}

	//#endregion

	//#region SQL_VIEWS

	async updateStatus(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'update_status',
				this.login.conta.pk_idconta,
				params.fk_iddicionariotabela,
				params.ids_array,
				params.status,
				params.table_name,
				params.table_status_name
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateStatus')
		}

		return retorno
	}

	//#endregion

	//#region UPLOAD

	uploadFile(folder: any, fileName: string, file: File): Observable<any> {
		var retorno = null

		try {
			// https://stackoverflow.com/questions/47034375/angular-file-upload-progress-percentage
			// https://www.itsolutionstuff.com/post/angular-9-file-upload-exampleexample.html
			// https://www.positronx.io/angular-file-upload-with-progress-bar-tutorial/
			const headers: any = this.headers

			const urlAPI = this.urlServerAPI + 'upload'

			let formData = new FormData()
			formData.append('fileName', fileName)
			formData.append('folder', folder)
			formData.append('file', file, file.name)

			// https://www.bennadel.com/blog/3596-uploading-multiple-files-in-a-single-form-post-with-httpclient-in-angular-7-2-12.htm
			// NOTE: When using a FormData instance to define the request BODY, the
			// correct Content-Type will be automatically provided, along with the
			// necessary "boundary" option that delimits the field values. If you
			// attempt to define the Content-Type explicitly, the "boundary" value
			// will be omitted from the post which will prevent the Lucee Server
			// parsing the request into the Form scope properly.
			// --
			// {
			// headers: {
			// "Content-Type": "multipart/form-data"
			// }
			// }
			delete headers['Content-Type']

			retorno = this.http
				.post<any>(urlAPI, formData, {
					headers,
					reportProgress: true,
					observe: 'events',
					responseType: 'json'
				})
				.pipe(
					map((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								// start upload
								return {
									status: 'progress',
									message: 0
								}

							case HttpEventType.UploadProgress:
								// uploading
								const progress = Math.round((100 * event.loaded) / event.total)
								return {
									status: 'progress',
									message: progress
								}

							case HttpEventType.Response:
								// complete upload
								return {
									status: 'OK',
									message: 0
								}

							default:
								return `Unhandled event: ${event.type}`
						}
					})
				)
		} catch (error) {
			this.handleError(error, 'uploadFile')
		}

		return retorno
	}

	async deleteFile(body: any): Promise<any> {
		var retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'upload/delete'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'deleteFile')
		}

		return retorno
	}
	//#endregion

	//#region STORAGE_LOCAL
	async getStorageLocal(key: string): Promise<any> {
		var retorno = null

		try {
			retorno = await this.storage.get(key).toPromise()
		} catch (error) {
			this.handleError(error, 'getStorageLocal')
		}

		return retorno
	}

	setStorageLocal(key: string, value: any) {
		localStorage.setItem(key, JSON.stringify(value))
		this.storage.set(key, value).subscribe({
			next: () => {},
			error: error => {
				console.error('setStorageLocal: ' + error)
			}
		})
	}

	async clearStorageLocal() {
		const rememberLoginInfo = localStorage.getItem('remember_login')
		localStorage.clear()
		this.localStorage.clear().subscribe(() => {})
		this.storage.clear().subscribe(() => {})
		if (rememberLoginInfo) {
			localStorage.setItem('remember_login', rememberLoginInfo)
		}
	}

	//#endregion

	//#region SMS
	async sendSMS(params: any): Promise<any> {
		var retorno = null

		try {
			for (let index = 0; index < params.telefones.length; index++) {
				const numero = '+55' + params.telefones[index]

				var body = {
					PhoneNumber: numero,
					Message: params.message
				}

				const urlAPI = this.urlServerAPI + 'send_sms'

				retorno = await this.http.post<any>(urlAPI, body).toPromise()
			}
		} catch (error) {
			this.handleError(error, 'sendSMS')
		}

		return retorno
	}

	//#endregion

	//#region RELATORIOS

	async getRelatorioTelefones(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'rel_telefone',
				params.setor, // id setor
				params.vinculo,
				// params.tiposPessoas && params.tiposPessoas[0] > 0  ? params.tiposPessoas.toString() : null, //separado por virgula .toString(); // array.toString(); // '1,2,3'
				params.tiposPessoas,
				!params.status || params.status.length == 2 ? null : params.status[0],
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy.toString(),
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRelatorioTelefones')
		}

		return retorno
	}

	async getRelatorioAuditorias(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'rel_auditoria',
				params.operador > 0 ? params.operador : 0,
				params.dicionario,
				params.crud != '0' ? params.crud : null,
				params.dataInicio + 'T' + params.horaInicio + ':00',
				params.dataFim + 'T' + params.horaFim + ':59',
				params.gerar_estatistica,
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy.toString(),
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRelatorioAuditorias')
		}

		return retorno
	}

	async getRelatorioLogins(params: any): Promise<any> {
		var retorno = null

		try {
			var body = [
				'rel_login',
				params.operador > 0 ? params.operador : 0,
				params.sentido != '' ? params.sentido : null,
				params.dataInicio + 'T' + params.horaInicio + ':00',
				params.dataFim + 'T' + params.horaFim + ':59',
				this.getRowLimit, // max_limit INT,
				params.offset,
				params.sortBy.toString(),
				params.sortDesc ? 'DESC' : 'ASC'
			]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getRelatorioLogins')
		}

		return retorno
	}

	//#endregion

	//#region API_TERCEIROS

	// https://github.com/filipedeschamps/cep-promise
	// consulta três serviços simultaneamente e retorna o primeiro que responder
	// a fazer
	async buscarCEP(cep: string): Promise<any> {
		var retorno = null
		const params: HttpParams = new HttpParams().set('dontIntercept', 'true')

		const options = {
			params
		}

		try {
			retorno = await this.http.get(`https://viacep.com.br/ws/${cep}/json`, options).toPromise()

			// upperCase
			this.toUpperCase(retorno)
		} catch (error) {
			this.handleError(error, 'buscarCEP')
		}

		return retorno
	}

	async buscarCEPComTimeout(cep: string): Promise<any> {
		var retorno = null
		const params: HttpParams = new HttpParams().set('dontIntercept', 'true')

		const options = {
			params
		}

		try {
			retorno = await this.http
				.get(`https://viacep.com.br/ws/${cep}/json`, options)
				.pipe(
					timeout(5000),
					catchError(e => {
						return of({})
					})
				)
				.toPromise()

			// upperCase
			this.toUpperCase(retorno)
		} catch (error) {
			this.handleError(error, 'buscarCEPComTimeout')
		}

		return retorno
	}

	async buscarCNPJ(cnpj: string): Promise<any> {
		let retorno = null

		try {
			let headers = {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Headers': 'Content-Type',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Max-Age': '86400', // cache for 1 day
				Cookie: 'JSESSIONID=0e410cf8f1a222e61c620e689abdb9b5b3ce8428'
			}

			let url = `https://cors-anywhere.herokuapp.com/https://www.receitaws.com.br/v1/cnpj/${cnpj}`

			retorno = await this.http.get(url).toPromise()
		} catch (error) {
			this.handleError(error, 'buscarCNPJ')
		}

		return retorno
	}

	//#endregion

	//#region DASHBOARDS
	async getDashboard_1(params: any): Promise<any> {
		var retorno = null

		try {
			var body = ['dashboard_1', this.login.conta.pk_idconta]

			const urlAPI = this.urlServerAPI + 'sql_views'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getDashboard_1')
		}

		return retorno
	}
	//#endregion

	//#region HANDLERS

	/**
	 * Handle Http operation that failed.
	 * https://angular.io/tutorial/toh-pt6
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	handleError(error: any, operation: string): void {
		// TODO: send the error to remote logging infrastructure

		// TODO: better job of transforming error for user consumption

		console.log(`${operation} failed: ${error.message}`)

		if (error.error && error.error.response) {
			console.log(
				`${operation}, Code: ${error.error.response.code}, Details: ${error.error.response.message}`
			)
		}

		if (
			error.status == 401 &&
			error.error &&
			error.error.response &&
			error.error.response.code == 1171
		) {
			// Registrando a saída do sistema para a conta do usuário logado atual
			this.logout(false)

			// get current url to return
			// Voltar para a tela anterior após login expirar
			let return_url =
				(this.platformLocation as any).location.pathname +
				(this.platformLocation as any).location.search

			let href = (this.platformLocation as any).location.href

			if (href.indexOf('login') < 0) {
				// TokenExpiredError
				this.errorMessageShow(error)

				// Redirect to session expired
				// https://jasonwatmore.com/post/2016/12/08/angular-2-redirect-to-previous-url-after-login-with-auth-guard
				this.router.navigate(['/login/authentication/lock-screen-v2'], {
					queryParams: {
						returnUrl: return_url
					}
				})
			}
		}

		// return error
		throw error
	}
	//#endregion

	//#region UTIL
	/**
	 * S3 - Get link file
	 * @param filePath
	 * @param refreshImage
	 * @returns
	 */
	public getUrlImage(filePath, forceRefreshImage: boolean = false) {
		let ret = 'https://dest-clinfluencer-imagens.s3.amazonaws.com' + filePath

		if (forceRefreshImage) {
			ret += '?' + new Date().getTime()
		}

		return ret
	}

	isNumber(n): any {
		let retorno = null
		try {
			retorno = !isNaN(parseFloat(n)) && isFinite(n)
		} catch (error) {}
		return retorno
	}

	padLeft(text: string, padChar: string, size: number): string {
		return (String(padChar).repeat(size) + text).substr(size * -1, size)
	}

	isCPF(strCPF): boolean {
		var Soma
		var Resto
		Soma = 0
		if (strCPF == '00000000000') {
			return false
		}

		for (let i = 1; i <= 9; i++) {
			Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
		}
		Resto = (Soma * 10) % 11

		if (Resto == 10 || Resto == 11) {
			Resto = 0
		}
		if (Resto != parseInt(strCPF.substring(9, 10))) {
			return false
		}

		Soma = 0
		for (let i = 1; i <= 10; i++) {
			Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
		}
		Resto = (Soma * 10) % 11

		if (Resto == 10 || Resto == 11) {
			Resto = 0
		}
		if (Resto != parseInt(strCPF.substring(10, 11))) {
			return false
		}
		return true
	}

	parseToInt(value): number {
		let retorno: number = 0

		try {
			if (value && value.length > 0) {
				retorno = parseInt(value)
			}
		} catch (error) {}

		return retorno
	}

	stringToJSON(value): any {
		try {
			if (value) {
				value = JSON.parse(value)
			}
		} catch (error) {
			console.log(value)
			console.log(error)
		}

		return value
	}

	jsonToString(value): any {
		try {
			if (value) {
				value = JSON.stringify(value)
			}
		} catch (error) {
			console.log(error)
		}

		return value
	}

	/**
	 * https://stackoverflow.com/questions/35754586/format-date-as-dd-mm-yyyy-using-pipes
	 * @param value The date expression: a `Date` object,  a number
	 * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
	 * @param format The date/time components to include, using predefined options or a
	 * custom format string.
	 * shortDate: 31/05/2020
	 * short: 31/05/2020 12:36
	 *
	 */
	dateIsoToString(value, format = 'shortDate'): any {
		try {
			if (value) {
				var datePipe = new DatePipe('pt-BR') // Use your own locale
				value = datePipe.transform(value, format)
			}
		} catch (error) {
			console.log(error)
		}

		return value
	}

	today(): string {
		let today: number = Date.now()
		return this.dateIsoToString(today)
	}

	/**
	 * https://stackoverflow.com/questions/43407338/how-to-add-hours-to-a-parse-moment-date/43407371
	 * https://stackoverflow.com/questions/17333425/add-a-duration-to-a-moment-moment-js
	 * @param minutes Pega a data atual e adiciona os minutos a hora atual
	 */
	getDateAddMinute(minutes, format: string = 'YYYY-MM-DD') {
		let a = new Date()

		var startdate = moment(a)

		var returned_endate = moment(startdate).add(minutes, 'minutes') // see the cloning?

		let b = new DateFormatPipe().transform(returned_endate, format)

		return b
	}

	getFormatDate(date, format: string = 'YYYY-MM-DD') {
		let a = Date.parse(date)

		let b = new DateFormatPipe().transform(a, format)

		return b
	}

	getDateAddDay(days, format: string = 'YYYY-MM-DD') {
		let a = new Date()

		var startdate = moment(a)

		var returned_endate = moment(startdate).add(days, 'days') // see the cloning?

		let b = new DateFormatPipe().transform(returned_endate, format)

		return b
	}

	// 2023-04-13T13:50:00.000Z
	getDateFormatComHoraMinutoESegundo(data, hora) {
		let retorno

		if (hora.indexOf('T') > 0) {
			hora = hora.substr(hora.indexOf('T') + 1, 5)
		}

		if (data.indexOf('T') > 0) {
			data = this.getFormatDate(data)
		}

		// Valida se ta passando o : na horario
		if (!hora.includes(':')) {
			hora = this.getFormatHour(hora)
		}

		// const date = new Date(data);
		const formattedDate = data.toString('YYYY-MM-DD')
		let horario = hora.toString('HH:mm')

		retorno = formattedDate + 'T' + horario + ':00.000Z'

		return retorno
	}

	getDateAddHour(hours, format: string = 'YYYY-MM-DD') {
		let a = new Date()

		var startdate = moment(a)

		var returned_endate = moment(startdate).add(hours, 'hours') // see the cloning?

		let b = new DateFormatPipe().transform(returned_endate, format)

		return b
	}

	getDateISO(format: string = 'YYYY-MM-DD'): string {
		// https://github.com/urish/ngx-moment/issues/158
		let a = new Date()
		let b = new DateFormatPipe().transform(a, format)
		return b
	}

	toUpperCase(data: any): any {
		try {
			if (!data) {
				return null
			}

			if (typeof data === 'string' || data instanceof String) {
				data = data.toUpperCase()
			} else {
				// upperCase
				for (var key in data) {
					var value = data[key]

					if (value) {
						value = value.toUpperCase()
						data[key] = value
					}
				}
			}
		} catch (error) {}

		return data
	}

	// https://eloquentjavascript.net/09_regexp.html
	getOnlyNumbers(value) {
		if (value && value.length > 0) {
			// /\d/	Any digit character
			value = value.match(/\d/g).join('')
		}
		return value
	}

	getFormatHour(value) {
		const hora = Math.floor(value / 100) // extrai as horas do número
		const minutos = value % 100 // extrai os minutos do número

		const data = new Date() // cria uma nova instância de Date com a data e hora atuais
		data.setHours(hora, minutos) // define as horas e minutos no objeto Date

		const horaFormatada = data.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false
		}) // formata a hora como "HH:mm"
		return horaFormatada
	}

	// https://eloquentjavascript.net/09_regexp.html
	getOnlyNumbersAndLetters(value) {
		if (value && value.length > 0) {
			// Allow numbers, alpahbets
			// /\w/	An alphanumeric character (“word character”
			value = value.match(/\w/g).join('')
		}
		return value
	}

	isJson(str) {
		try {
			JSON.parse(str)
		} catch (e) {
			return false
		}

		return true
	}

	parseCurrencyFormat(value: any) {
		let retorno = value

		try {
			if (typeof value === 'string' || value instanceof String) {
				retorno = parseFloat(value.toString()).toFixed(2)
			} else {
				retorno = value.toFixed(2)
			}

			// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
			retorno = new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL'
			}).format(retorno)
		} catch (error) {
			console.error('parseCurrencyFormat: ' + error)
		}

		return retorno
	}

	/**
	 * Compares two Date objects and returns e number value that represents
	 * the result:
	 * 0 if the two dates are equal.
	 * 1 if the first date is greater than second.
	 * -1 if the first date is less than second.
	 * @param date1 First date object to compare.
	 * @param date2 Second date object to compare.
	 */
	compareDate(date1: Date, date2: Date): number {
		// With Date object we can compare dates them using the >, <, <= or >=.
		// The ==, !=, ===, and !== operators require to use date.getTime(),
		// so we need to create a new instance of Date with 'new Date()'
		let d1 = new Date(date1)
		let d2 = new Date(date2)

		// Check if the dates are equal
		let same = d1.getTime() === d2.getTime()
		if (same) {
			return 0
		}

		// Check if the first is greater than second
		if (d1 > d2) {
			return 1
		}

		// Check if the first is less than second
		if (d1 < d2) {
			return -1
		}
	}

	getFirstWord(value: string): string {
		if (value == null) {
			return value
		}
		value = value.trim()
		const words = value.split(' ')
		return words.length > 0 ? words[0] : value
	}

	removeSpacesAndAccent(value: string): string {
		if (value == null) {
			return value
		}

		value = value.trim().toUpperCase()
		// Remove double space
		value = value.replace(/\s+/g, ' ')
		// Remove accent
		value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

		return value
	}

	removeAllSpacesAndAccent(value: string): string {
		if (value == null) {
			return value
		}

		value = value.trim().toUpperCase()
		// Remove all spaces
		value = value.replace(/\s+/g, '')
		// Remove accent
		value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

		return value
	}

	// https://flaviocopes.com/how-to-trim-leading-zero-number/
	removeZerosLeft(value: string): string {
		if (value == null) {
			return value
		}

		try {
			value = value.trim()

			// You can also go the regular expression route, like this:
			// number.replace(/^0+/, '')

			let number = parseInt(value, 10)
			value = number.toString()
		} catch (error) {}

		return value
	}

	//#endregion

	//#region METABASE

	async getLinkMetabase(body) {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'metabase/token/'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getLinkMetabase')
		}

		return retorno
	}

	//#endregion

	//#region CORRETOR

	async getByCorretorPorCodigoS4(params: any): Promise<any> {
		let retorno = null
		try {
			const urlAPI = `${this.urlServerAPI}contas/getCodigoCorretor`
			retorno = await this.http.post<any>(urlAPI, params).toPromise()
		} catch (error) {
			this.handleError(error, 'getByCorretorPorCodigoS4')
		}

		return retorno
	}

	async getCorretorPorCpf(params: any): Promise<any> {
		let retorno = null

		try {
			// let con_cods4_plataforma = 0
			// let con_cods4_corretor = 0

			// // Se nao for ADM
			// if (this.login.isAdm == 0) {
			//   con_cods4_plataforma = this.login.conta.con_cods4_plataforma ? this.login.conta.con_cods4_plataforma : 0
			//   con_cods4_corretor = this.login.conta.con_cods4_corretor ? this.login.conta.con_cods4_corretor : 0
			// }
			// var user = await this.getUser();
			// let liberado = user.temPermissao(Dicionario.BUSCA_DE_BENEFICIARIO_LIVRE, 'R') ? 1 : 0

			let body = {
				cpf: params.searchQuery
			}

			const urlAPI = this.urlServerAPI + 's4e/buscar_corretor_por_cpf'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getAuditoriaPorDicionarioEId')
		}

		return retorno
	}

	//#endregion
	//#region BENEFICIARIO

	async getBeneficiariosPorCpf(params: any): Promise<any> {
		let retorno = null

		try {
			let con_cods4_plataforma = 0
			let con_cods4_corretor = 0

			// Se nao for ADM
			if (this.login.isAdm == 0) {
				con_cods4_plataforma = this.login.conta.con_cods4_plataforma
					? this.login.conta.con_cods4_plataforma
					: 0
				con_cods4_corretor = this.login.conta.con_cods4_corretor
					? this.login.conta.con_cods4_corretor
					: 0
			}
			let user = await this.getUser()
			let liberado = user.temPermissao(Dicionario.BUSCA_DE_BENEFICIARIO_LIVRE, 'R') ? 1 : 0

			let body = {
				pesq: params.searchQuery,
				cods4_plataforma: con_cods4_plataforma,
				cods4_vendedor: con_cods4_corretor,
				// liberado: this.login.isAdm
				liberado: liberado
			}

			const urlAPI = this.urlServerAPI + 's4e/buscar_beneficiario_por_cpf'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getBeneficiariosPorCpf')
		}

		return retorno
	}

	async getClientePJ(params: any): Promise<any> {
		let retorno = null

		try {
			let con_cods4_plataforma = 0
			let con_cods4_corretor = 0

			// Se nao for ADM
			if (this.login.isAdm == 0) {
				con_cods4_plataforma = this.login.conta.con_cods4_plataforma
					? this.login.conta.con_cods4_plataforma
					: 0
				con_cods4_corretor = this.login.conta.con_cods4_corretor
					? this.login.conta.con_cods4_corretor
					: 0
			}
			let user = await this.getUser()
			let liberado = user.temPermissao(Dicionario.BUSCA_POR_CLIENTE_PJ, 'R') ? 1 : 0

			let body = {
				pesq: params.searchQuery,
				cods4_plataforma: con_cods4_plataforma,
				cods4_vendedor: con_cods4_corretor,
				// liberado: this.login.isAdm
				liberado: liberado
			}

			const urlAPI = this.urlServerAPI + 's4e/buscar_cliente_pj'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getClientesPJ')
		}

		return retorno
	}

	async getBeneficiarioCarteiraDigital(cod_sequencial: any): Promise<any> {
		let retorno = null

		try {
			let body = {
				pesq: cod_sequencial
			}

			const urlAPI = this.urlServerAPI + 's4e/buscar_beneficiario_carteira_digital'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getBeneficiarioCarteiraDigital')
		}

		return retorno
	}

	//#endregion

	//#region Help Video

	getVideos() {
		return [
			{
				image: 'avatarAgiben.jpg',
				title: 'Boas Vindas',
				description: 'Sejam bem vindos ao Agiben, uma plataforma, vários benefícios.',
				price: '00:21',
				partialUrl: 'GjxrJq2tRPk',
				tag: 'Todos',
				id: 0
			},
			{
				image: 'avatarAgiben.jpg',
				title: 'Introdução',
				description:
					'Saiba um pouco mais sobre a Agiben o objetivo da plataforma e seus endereços de acesso.',
				price: '02:46',
				partialUrl: 'UiHK3JmtWkA',
				tag: 'Todos ',
				id: 1
			},
			{
				image: 'avatarAgiben.jpg',
				title: 'Primeiro Acesso',
				description:
					'Tutorial de como realizar o primeiro acesso ao sistema e as primeiras funcionalidades.',
				price: '02:01',
				partialUrl: 'O6bwiwhX4dE',
				tag: 'Todos ',
				id: 2
			},
			{
				image: 'avatarAgiben.jpg',
				title: 'Campanhas',
				description:
					'Fique por dentro sobre as campanhas e como utiliza-las para realizar suas vendas.',
				price: '05:46 ',
				partialUrl: '5Bm-oDv5NQ8',
				tag: 'Todos ',
				id: 3
			},
			{
				image: 'avatarAgiben.jpg',
				title: 'Como criar o Link de Vendas?',
				description: 'Saiba como gerar o seu link de vendas da nossa plataforma',
				price: '02:01',
				partialUrl: 'ejyZmjwPQck',
				tag: 'Todos ',
				id: 4
			},
			{
				image: 'avatarAgiben.jpg',
				title: 'Preenchendo a venda para o seu cliente',
				description:
					'Preencher a venda e mandar apenas para o cliente realizar o pagamento? Saiba como.',
				price: '02:01',
				partialUrl: '0imIiQqcmQA',
				tag: 'Todos ',
				id: 5
			},
			{
				image: 'avatarAgiben.jpg',
				title: 'Solicitando o saque.',
				description:
					'Você é uma plataforma e deseja solicitar o saque disponível de comissão? Saiba como.',
				price: '02:01',
				partialUrl: 'NjbpLyTktPM',
				tag: 'Todos ',
				id: 6
			}
			// , {
			//   image: 'avatarAgiben.jpg',
			//   title: 'Cadastro de colaboradores',
			//   description: 'Tutorial de como cadastrar colaboradores e dependentes na sua conta de empresa.',
			//   price: '02:01',
			//   partialUrl: '0LXcUhaE7iE',
			//   tag: 'Todos ',
			//   id: 1
			// }
		]
	}
	//#region POSTGRES

	async execSQL_Postgres(body: any): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPI + 'postgres/exec_sql'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'exec_sql')
		}

		return retorno
	}

	//#endregion

	//#region COMRPOMISSO NEST

	async getCompromissosTodos(params: any): Promise<any> {
		let retorno = null

		try {
			let body = {
				pesq: params.searchQuery,
				com_tipo: params.com_tipo,
				com_status:
					!params.com_status || params.com_status.length == 2 ? null : params.com_status[0],
				max_limit: params.max_limit, // max_limit INT,
				pos_offset: params.pos_offset,
				orderby: params.orderby,
				direction: params.direction ? 'DESC' : 'ASC'
			}

			// var body = {
			//   pesq: null,
			//   com_tipo: "E",
			//   com_status: "A",
			//   max_limit: 5,
			//   pos_offset: 0,
			//   orderby: "com_datahora",
			//   direction: "ASC"
			// };

			const urlAPI = this.urlServerAPINest + 'compromissos/filter'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'getCompromisso')
		}

		return retorno
	}

	async getCompromissoPorIDEmpresa(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/' + id + '/busca_por_id_empresa'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getCompromissoPorIDEmpresa')
		}

		return retorno
	}

	async getCompromissoPorIDConta(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/' + id + '/busca_por_id_conta'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getCompromissoPorIDConta')
		}

		return retorno
	}

	async getCompromissoPorIDContaMesAno(idConta: number, mes: number, ano: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest +
				'compromissos/' +
				idConta +
				'/' +
				mes +
				'/' +
				ano +
				'/busca_por_id_usuario_mes_ano'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getCompromissoPorIDContaMesAno')
		}

		return retorno
	}

	async getCompromissoNestPorID(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/' + id

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			console.log(error)
			this.handleError(error, 'getCompromissoNestPorID')
		}

		return retorno
	}

	async isValidISODate(dateStr: string) {
		const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
		return isoRegex.test(dateStr)
	}

	async updateCompromissoNestPorID(params: any, id: number): Promise<any> {
		let retorno = null

		let body = {
			com_duracao: params.com_duracao,
			com_inicio: this.getDateFormatComHoraMinutoESegundo(params.com_inicio, params.com_horario),
			com_obs: params.com_obs,
			com_tipo: params.com_tipo,
			com_statusreuniao: params.com_statusreuniao
		}

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()

			let data = retorno[0]
		} catch (error) {
			console.log(error)
			this.handleError(error, 'updateCompromissoNestPorID')
		}

		return retorno
	}

	async createCompromissoNest(params: any): Promise<any> {
		let retorno = null

		try {
			let body = {
				fk_idempresa: params.fk_idempresa,
				fk_idconta: params.fk_idconta,
				com_inicio: this.getDateFormatComHoraMinutoESegundo(params.com_inicio, params.com_horario),
				com_duracao: params.com_duracao,
				com_statusreuniao: params.com_statusreuniao,
				com_tipo: params.com_tipo,
				com_obs: params.com_obs
			}

			const urlAPI = this.urlServerAPINest + 'compromissos'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createCompromissosNest')
		}

		return retorno
	}

	async removeCompromisso(id: any): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'compromissos/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeCompromisso')
		}

		return retorno
	}

	//#endregion

	//#region EMPRESA NEST
	async getEmpresaNestPorID(id: number): Promise<any> {
		let retorno = null
		try {
			const urlAPI = this.urlServerAPINest + 'empresas/' + id
			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getEmpresaNestPorID')
		}

		return retorno
	}

	async createEmpresaNest(body: any): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'empresas/'
			this.checkEmpresa(body)

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createEmpresaNest')
		}

		return retorno
	}

	async updateEmpresaNest(body: any, id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'empresas/' + id

			body.emp_cnpj = this.getOnlyNumbers(body.emp_cnpj)

			// verificar campos que estão sendo salvos no backend

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateEmpresaNest')
		}

		return retorno
	}

	async removeEmpresaNest(id: any): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'empresas/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeEmpresaNest')
		}

		return retorno
	}

	//#endregion

	//#region ATIVIDADE NEST
	async getAtividadesPorIdempresa(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'atividades/' + id + '/busca_por_id_empresa'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getAtividadesPorIdempresa')
		}

		return retorno
	}

	async createAtividadeNest(params: any): Promise<any> {
		let retorno = null
		try {
			let body = {
				fk_idempresa: params.fk_idempresa,
				ati_comentarios: params.ati_comentarios,
				fk_idconta: params.fk_idconta,
				ati_data: this.getDateFormatComHoraMinutoESegundo(params.ati_data, params.ati_datahora),
				ati_duracao: params.ati_duracao === 'NaN' ? params.ati_duracao : 30,
				ati_tipo: params.ati_tipo,
				ati_status: params.ati_status
			}

			const urlAPI = this.urlServerAPINest + 'atividades'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createAtividadesNest')
		}

		return retorno
	}

	async removeAtividade(id: any): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'atividades/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeAtividade')
		}

		return retorno
	}

	async getAtividadePorID(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'atividades/' + id
			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getAtividadePorID')
		}

		return retorno
	}

	checkAtividade(body: any) {}

	async updateAtividade(params: any, id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'atividades/' + id

			// 2023 - 04 - 16T17: 00: 00
			let data = this.getDateFormatComHoraMinutoESegundo(params.ati_data, params.ati_datahora)

			if (params.ati_tipo === 'L') {
				params.ati_duracao = 30
			}

			let body = {
				fk_idempresa: params.fk_idempresa,
				ati_comentarios: params.ati_comentarios,
				ati_data: this.getDateFormatComHoraMinutoESegundo(params.ati_data, params.ati_datahora),
				ati_duracao: params.ati_duracao === 'NaN' ? params.ati_duracao : 30,
				ati_tipo: params.ati_tipo,
				ati_status: params.ati_status
			}

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateAtividade')
		}

		return retorno
	}

	async getAtividadePorIDContaMesAno(idConta: number, mes: number, ano: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest +
				'atividades/' +
				idConta +
				'/' +
				mes +
				'/' +
				ano +
				'/busca_por_id_usuario_mes_ano'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getAtividadePorIDContaMesAno')
		}

		return retorno
	}

	//#endregion

	//#region CONTATO EMPRESA NEST
	async getContatoPorIdempresa(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'contatos/' + id + '/busca_por_id_empresa'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getContatoPorIdempresa')
		}

		return retorno
	}

	async createContatoNest(params: any): Promise<any> {
		let retorno = null

		try {
			let body = {
				fk_idempresa: params.pk_empresa,
				fk_idconta: params.fk_idconta,
				fk_idcargo: params.cargo,
				con_numero: params.telefone2,
				con_nome: params.nome,
				con_email: params.email,
				con_celular: params.telefone1,
				con_cpf: params.cpf,
				con_sexo: params.sexo,
				con_responsavellegal: params.responsavel_legal
			}

			const urlAPI = this.urlServerAPINest + 'contatos'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'createContatoNest')
		}

		return retorno
	}

	async removeContato(id: any): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'contatos/' + id

			await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'removeContato')
		}

		return retorno
	}

	async getContatoPorID(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'contatos/' + id
			retorno = await this.http.get<any>(urlAPI).toPromise()

			let data = retorno[0]
		} catch (error) {
			this.handleError(error, 'getContatoPorID')
		}

		return retorno
	}

	checkContato(body: any) {}

	async updateContato(params: any, id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'contatos/' + id

			let body = {
				fk_idempresa: params.fk_idempresa,
				fk_idconta: params.fk_idconta,
				con_numero: params.telefone2,
				con_nome: params.nome,
				con_email: params.email,
				con_celular: params.telefone1,
				con_cpf: params.cpf,
				con_sexo: params.sexo,
				con_responsavellegal: params.responsavel_legal,
				fk_idcargo: params.cargo
			}

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateContato')
		}

		return retorno
	}

	//#endregion

	//#region COMPROMISSO EMPRESA NEST

	//#region CLIENTE_SALES = VIDA

	async getClientePorIDEmpresaCodAssociadoPostgres(
		idEmpresa: number,
		codAssociado: number
	): Promise<any> {
		const urlAPI = `${this.urlServerAPINest}clientes/${idEmpresa}/buscar_por_cod_associado/${codAssociado}`
		return this.http.get<any>(urlAPI).toPromise()
	}

	// BUSCA SE O CLIENT JÁ ESTA CADASTRADO NA BASE DO POSTGRES - AGIBEN
	async getClientePorIDEmpresaCPFPostgres(idEmpresa: number, cpf: string): Promise<any> {
		let retorno = null
		try {
			const urlAPI =
				this.urlServerAPINest +
				'clientes/' +
				idEmpresa +
				'/' +
				cpf +
				'/busca_por_id_empresa_cpf_agiben'
			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getClientePorIDEmpresaCPF')
		}
		return retorno
	}

	async getClientePorIDEmpresaCPFS4E(idEmpresa: number, cpf: string): Promise<any> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest +
				'clientes/' +
				idEmpresa +
				'/' +
				cpf +
				'/busca_por_id_empresa_cpf_s4e'
			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			// this.handleError(error, "getClientePorIDEmpresaCPF");
			console.log('Erro ao buscar beneficiário no S4E ou não foi encontrado')
		}

		return retorno
	}

	async inserirVidaPostgresNest(params: any): Promise<any> {
		let retorno = null
		try {
			const urlAPI = this.urlServerAPINest + 'clientes'

			retorno = await this.http.post<any>(urlAPI, params).toPromise()
		} catch (error) {
			console.log('erro criar vida: ', error)
			this.handleError(error, 'inserirVidaPostgresNest')
			retorno = error
		}

		return retorno
	}

	async inserirVidaNest(params: any): Promise<any> {
		let retorno = null

		try {
			const body = {
				cli_nome: params.vida_nome.value,
				cli_cpf: params.vida_cpf.value,
				cli_nomemae: params.vida_nomemae.value,
				cli_datanascimento: params.vida_nascimento.value,
				cli_sexo: params.vida_sexo.value,
				cli_telefonecelular: params.vida_telefone.value,
				cli_email: params.vida_email.value,
				cli_grauparentesco: params.cli_grauparentesco.value || 1,
				cli_matricula: params.vida_matricula.value,
				cli_idplano: params.cco_id_produto_s4.value,
				fk_idtitular: params?.fk_idtitular.value,
				cli_tipo: params.vida_tipo.value,
				cli_status: params?.cli_status || 'A',
				cli_titular: null,
				fk_idempresa: params.fk_idempresa.value,
				cli_enviar_para_s4E: params.cli_enviar_para_s4E.value,
				cli_cod_empresa_s4E: params.cli_cod_empresa_s4E.value,
				cli_codigoassociados4e: params.cli_codigoassociados4e.value,
				clienteCompromisso: {
					fk_idprodutoempresa: null,
					cco_id_produto_s4: params.cco_id_produto_s4.value,
					fk_idcompromisso: params.vida_idcompromisso.value,
					cco_valor_produto: params.cco_valor_produto.value
				}
			}

			console.log(body)

			const urlAPI = this.urlServerAPINest + 'clientes/criarcomclientecompromisso'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			console.log('erro criar vida: ', error)
			this.handleError(error, 'inserirVidaNest')
			retorno = error
		}

		return retorno
	}

	async updateVidaNest(params: any, id: number): Promise<any> {
		let retorno = null
		try {
			const idPRoduto = params.cco_id_produto_s4.value.split('-')

			const body = {
				cli_nome: params.vida_nome.value,
				cli_cpf: params.vida_cpf.value,
				cli_nomemae: params.vida_nomemae.value,
				cli_datanascimento: params.vida_nascimento.value,
				cli_sexo: params.vida_sexo.value,
				cli_telefonecelular: params.vida_telefone.value,
				cli_email: params.vida_email.value,
				cli_grauparentesco: params.cli_grauparentesco.value || 1,
				cli_matricula: params.vida_matricula.value,
				cli_idplano: Number(idPRoduto[0]),
				cli_tipo: params.vida_tipo.value,
				cli_status: params?.cli_status || 'C',
				fk_idempresa: params.fk_idempresa.value
			}

			const urlAPI = this.urlServerAPINest + 'clientes/' + id

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			console.log('erro atualizar vida: ', error)
			this.handleError(error, 'updateVidaNest')
		}
	}

	async removerVidaNest(id: any): Promise<any> {
		try {
			let retorno

			const urlAPI = this.urlServerAPINest + 'clientes/' + id

			retorno = await this.http.delete<any>(urlAPI).toPromise()
		} catch (error) {
			console.log('Erro ao remover vida: ', error)
			this.handleError(error, 'removerVidaNest')
		}
	}

	async sincronizarVidaNest(params: any): Promise<any> {
		let retorno = null

		try {
			const idPRoduto = params.cco_id_produto_s4.split('-')

			const body = {
				cli_nome: params.vida_nome,
				cli_cpf: params.vida_cpf,
				cli_nomemae: params.vida_nomemae,
				cli_datanascimento: params.vida_nascimento,
				cli_sexo: Number(params.vida_sexo),
				cli_telefonecelular: params.vida_telefone,
				cli_email: params.vida_email,
				cli_grauparentesco: params.cli_grauparentesco,
				cli_matricula: params.vida_matricula,
				pk_idcliente: params.pk_idcliente,
				cli_cod_empresa_s4E: params.cli_cod_empresa_s4E,
				cco_id_produto_s4: Number(idPRoduto[0]),
				cli_codigoassociados4e: params.cli_codigoassociados4e
			}

			const urlAPI = this.urlServerAPINest + 'clientes/enviarClienteVidaParaS4E'

			retorno = await this.http.post<any>(urlAPI, body).toPromise()
		} catch (error) {
			console.log('erro criar vida: ', error)
			this.handleError(error, 'sincronizarVidaNest')
			retorno = error
		}

		return retorno
	}

	async updateLinkVidaPorIdNest(link: any, id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI = this.urlServerAPINest + 'clientes/' + id

			const body = {
				cli_anexos: link
			}

			retorno = await this.http.patch<any>(urlAPI, body).toPromise()
		} catch (error) {
			this.handleError(error, 'updateLinkVidaPorIdNest')
		}

		return retorno
	}

	async getClientesPorIdCompromissoNest(id: number): Promise<any> {
		let retorno = null

		try {
			const urlAPI =
				this.urlServerAPINest + 'cliente_compromissos/' + id + '/busca_por_id_compromisso'

			retorno = await this.http.get<any>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getClientesPorIdCompromissoNest')
		}

		return retorno
	}

	//#endregion

	async getLiderados(
		id: number,
		params: ContasUsuariosLideradosParamsDTO
	): Promise<ContasUsuariosLideradosDTO> {
		try {
			const urlAPI = `${this.urlServerAPINest}contas/liderados/${id}`

			return await this.http.post<ContasUsuariosLideradosDTO>(urlAPI, params).toPromise()
		} catch (error) {
			this.handleError(error, 'getLiderados')
		}
	}

	async getLideradosSemFiltro(
		id: number,
	): Promise<LideradosDto[]> {
		try {
			const urlAPI = `${this.urlServerAPINest}contas/liderados/${id}`

			return await this.http.get<LideradosDto[]>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getLideradosSemFiltro')
		}
	}

	async getContaDetalhesPorId(id: number): Promise<ContasUsuariosDetalhesDTO> {
		try {
			const urlAPI = `${this.urlServerAPINest}contas/detalhes/${id}`
			return await this.http.get<ContasUsuariosDetalhesDTO>(urlAPI).toPromise()
		} catch (error) {
			this.handleError(error, 'getContaDetalhes')
		}
	}
	//#region CAMPANHA
}
