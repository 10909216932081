<default-header-title (onDismiss)="dismiss()" [tituloModal]="tituloModal"></default-header-title>

<div class="modal-body inclusao-vida-modal-height">
	<div [ngSwitch]="currentStep.getValue()">
		<div *ngSwitchCase="inclusaoVidaPopupStepEnum.STEP_FORM_INCLUSAO_VIDA">
			<app-inclusao-vida-avulsa-form-vida #inclusaoVidaAvulsaFormVida
				[formPrimeiraInclusao]="formPrimeiraInclusao" [dadosInclusaoVidas]="dadosInclusaoVidas"
				(onCarregarPlanoFromFormVidas)="carregarPlanoFromFormVidas($event)"></app-inclusao-vida-avulsa-form-vida>
		</div>

		<div *ngSwitchCase="inclusaoVidaPopupStepEnum.STEP_SELECAO_PRODUTO">
			<app-inclusao-vida-avulsa-form-plano #inclusaoVidaAvulsaFormPlano
				[formSelecaoProdutos]="formSelecaoProdutos"
				[listaProdutos]="dadosInclusaoVidas.listaProdutos"></app-inclusao-vida-avulsa-form-plano>
		</div>

		<div *ngSwitchCase="inclusaoVidaPopupStepEnum.STEP_ENVIO_FOTO">
			<app-inclusao-vida-avulsa-form-foto #inclusaoVidaAvulsaFormFoto
				[nomeArquivo]="dadosInclusaoVidas.nomeArquivo" [vidasNome]="dadosInclusaoVidas.nomeArquivo"
				[arquivosEnviados]="dadosInclusaoVidas.arquivosEnviados"
				[urlFicha]="dadosInclusaoVidas.urlFicha"></app-inclusao-vida-avulsa-form-foto>
		</div>

		<div *ngSwitchCase="inclusaoVidaPopupStepEnum.STEP_NOVA_INCLUSAO">
			<app-inclusao-vida-avulsa-nova-inclusao #inclusaoVidaAvulsaNovaInclusao
				[nomeTitular]="dadosInclusaoVidas.nomeTitular"></app-inclusao-vida-avulsa-nova-inclusao>
		</div>

		<div *ngSwitchCase="inclusaoVidaPopupStepEnum.STEP_FORM_NOVA_INCLUSAO_VIDA">
			<app-inclusao-vida-avulsa-form-vida-segunda [dadosInclusaoVidas]="dadosInclusaoVidas"
				[formSegundaInclusao]="formSegundaInclusao"
				#inclusaoVidaAvulsaFormVidaSegunda></app-inclusao-vida-avulsa-form-vida-segunda>
		</div>

		<div *ngSwitchCase="inclusaoVidaPopupStepEnum.STEP_NOVA_SELECAO_PRODUTO">
			<app-inclusao-vida-avulsa-form-plano #inclusaoVidaAvulsaFormPlanoSegundo
				[formSelecaoProdutos]="formSegundaSelecaoProdutos"
				[listaProdutos]="dadosInclusaoVidas.listaProdutos"></app-inclusao-vida-avulsa-form-plano>
		</div>
	</div>
</div>

<div class="modal-footer inclusao-vida-avulsa-footer">
	<div *ngIf="currentStep.getValue() !== inclusaoVidaPopupStepEnum.STEP_NOVA_INCLUSAO; else templateStepFinal">
		<div class="d-flex col-12 justify-content-end">
			<agi-button class="pl-2 btn-size"
				*ngIf="currentStep.getValue() !== inclusaoVidaPopupStepEnum.STEP_FORM_INCLUSAO_VIDA" cssClass="OUTLINE"
				[label]="labelVoltar" (onClick)="voltarStep()">
			</agi-button>
			<agi-button class="pl-2 btn-size" *ngIf="currentStep.getValue() !== inclusaoVidaPopupStepEnum.STEP_ENVIO_FOTO &&
					currentStep.getValue() !== inclusaoVidaPopupStepEnum.STEP_NOVA_SELECAO_PRODUTO" [label]="labelAvancar"
				[disabled]="desabilitarBotaoAvancar()" (onClick)="avancarStep()">
			</agi-button>
			<agi-button class="pl-2 btn-size" *ngIf="currentStep.getValue() === inclusaoVidaPopupStepEnum.STEP_ENVIO_FOTO ||
					currentStep.getValue() === inclusaoVidaPopupStepEnum.STEP_NOVA_SELECAO_PRODUTO" [label]="labelAvancar"
				[disabled]="desabilitarBotaoAvancar()" (onClick)="salvarInclusaoVida()" [loading]="loading">
			</agi-button>
		</div>
	</div>

	<ng-template #templateStepFinal>
		<div class="row">
			<agi-button class="pl-2 btn-size" (onClick)="finalizarInclusao()" cssClass="OUTLINE"
				label="{{ 'vidas.modal_vida_avulsa.finalizar_inclusao' | translate }}">
			</agi-button>
			<agi-button class="pl-2 btn-size" (onClick)="novaInclusaoDependente()"
				label="{{ 'vidas.modal_vida_avulsa.incluir_dependente' | translate }}">
			</agi-button>
		</div>
	</ng-template>
</div>

<default-swal #swalVidaImplantadaComSucesso confirmButtonText="{{'vidas.modal_migrar.entendi' | translate}}"
	icon="success" title="{{'vidas.modal_vida_avulsa.add_vida_succes_title' | translate}}" text=""
	(confirmCallback)="fecharSwalVidaImplantadaComSucessoOuNao()"
	(cancelCallback)="fecharSwalVidaImplantadaComSucessoOuNao()">
</default-swal>

<default-swal #swalVidasNaoImplantadas confirmButtonText="{{'vidas.modal_migrar.entendi' | translate}}" icon="warning"
	title="{{'vidas.modal_vida_avulsa.add_vida_fail_title' | translate}}"
	text="{{'vidas.modal_vida_avulsa.add_vida_fail_text' | translate}}"
	(confirmCallback)="fecharSwalVidaImplantadaComSucessoOuNao()"
	(cancelCallback)="fecharSwalVidaImplantadaComSucessoOuNao()">
</default-swal>
