import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'

// *******************************************************************************
// Layouts

import {Layout1Component} from './layout-1/layout-1.component'
import {Layout1FlexComponent} from './layout-1-flex/layout-1-flex.component'
import {Layout2Component} from './layout-2/layout-2.component'
import {Layout2FlexComponent} from './layout-2-flex/layout-2-flex.component'
import {LayoutWithoutNavbarComponent} from './layout-without-navbar/layout-without-navbar.component'
import {LayoutWithoutNavbarFlexComponent} from './layout-without-navbar-flex/layout-without-navbar-flex.component'
import {LayoutWithoutSidenavComponent} from './layout-without-sidenav/layout-without-sidenav.component'
import {LayoutHorizontalSidenavComponent} from './layout-horizontal-sidenav/layout-horizontal-sidenav.component'
import {LayoutBlankComponent} from './layout-blank/layout-blank.component'

// *******************************************************************************
// Components

import {LayoutNavbarComponent} from './layout-navbar/layout-navbar.component'
import {LayoutSidenavComponent} from './layout-sidenav/layout-sidenav.component'
import {LayoutFooterComponent} from './layout-footer/layout-footer.component'

// *******************************************************************************
// Libs

import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap'
import {SidenavModule} from '../../vendor/libs/sidenav/sidenav.module'

// *******************************************************************************
// Services

import {LayoutService} from './layout.service'
import {SharedModule} from '../shared.module'
import {NewPasswordPopupComponent} from '../others-component/new-password-popup/new-password-popup.component'
import {LayoutNavbarTotemComponent} from './layout-navbar-totem/layout-navbar-totem.component'
import {LayoutBlankTotemComponent} from './layout-blank-totem/layout-blank-totem.component'
import {VenderCampanhaPopupComponent} from '../others-component/vender-campanha-popup/vender-campanha-popup.component'
import {SwiperModule} from 'ngx-swiper-wrapper'
import {NotificacaoComponentComponent} from '../others-component/notificacao-component/notificacao-component.component'

// *******************************************************************************
//

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		NgbModule,
		SidenavModule,
		SwiperModule,
		SharedModule,
		NgbPopoverModule
	],
	declarations: [
		Layout1Component,
		Layout1FlexComponent,
		Layout2Component,
		Layout2FlexComponent,
		LayoutWithoutNavbarComponent,
		LayoutWithoutNavbarFlexComponent,
		LayoutWithoutSidenavComponent,
		LayoutHorizontalSidenavComponent,
		LayoutBlankComponent,
		LayoutBlankTotemComponent,
		LayoutNavbarComponent,
		LayoutNavbarTotemComponent,
		LayoutSidenavComponent,
		LayoutFooterComponent,
		VenderCampanhaPopupComponent,
		NewPasswordPopupComponent,
		NotificacaoComponentComponent
	],
	providers: [LayoutService],
	entryComponents: [NewPasswordPopupComponent, VenderCampanhaPopupComponent]
})
export class LayoutModule {}
