import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { FormControl, FormControlName, FormGroup, FormGroupDirective } from '@angular/forms'
import { NgSelectComponent } from '@ng-select/ng-select'
import { debounceTime, switchMap } from 'rxjs/operators'
import { SelectListItem } from 'src/app/model/interfaces/select-item-list'

const DEBOUNCE_TIME_IN_MILLISECONDS = 500

interface DynamicAttribute {
	[index: string]: unknown
}
interface SimpleSelectItem extends DynamicAttribute {
	id: number | string
	value: number | string
}

@Component({
	selector: 'simple-select',
	templateUrl: './simple-select.component.html',
	styleUrls: [
		'../../../../vendor/libs/ng-select/ng-select.scss',
		'../../../../vendor/libs/spinkit/spinkit.scss',
		'./simple-select.component.scss',
	],
})
export class SimpleSelectComponent implements OnInit, AfterViewInit {
	constructor(
		private cd: ChangeDetectorRef,
		private formControlNameDirective: FormControlName,
		private formGroupDirective: FormGroupDirective,
	) { }
	@ViewChild('simpleSelect') simpleSelect: NgSelectComponent

	@Input() formControl: FormControl

	@Input() closeOnSelect = true
	@Input() hideSelected = true
	@Input() clearable = true
	@Input() virtualScroll = true
	@Input() searchable = true
	@Input() defaultValue

	@Input() name = ''
	@Input() bindLabel = ''
	@Input() bindValue = ''
	@Input() items: any[] = []
	@Input() iconSrc = ''
	@Input() loadingText = 'Carregando...'
	@Input() placeholder = 'Selecione...'
	@Input() notFoundText = '* Nenhum registro foi encontrado'
	@Input() clearAllText = 'Limpar texto'
	@Input() typeToSearchText = 'Digite para buscar'

	@Input() loading = false
	@Input() isReadonly = false
	@Input() submitted = false

	@Input() selectUpperLabel = ''

	@Output() onClear: EventEmitter<any> = new EventEmitter()
	@Output() onScrollToEnd: EventEmitter<any> = new EventEmitter()
	@Output() onScroll: EventEmitter<any> = new EventEmitter()
	@Output() onSelect: EventEmitter<any> = new EventEmitter()

	selectTypeahead: EventEmitter<any> = new EventEmitter()
	valueFormGroup?: FormGroup
	valueFormControl?: FormControl

	value: any
	@Input() disabled = false
	@Input() multiple = false
	onChange: () => void
	onTouched: () => void

	@Input() reloadItems = (term): SelectListItem[] => {
		return []
	}

	@Input() compareWith = (a, b) => a === b

	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.valueFormGroup = this.formGroupDirective.form
		this.valueFormControl = this.formGroupDirective.getControl(this.formControlNameDirective)
		if (this.defaultValue !== undefined && this.items.length) {
			const item = this.items.find((data) => data.value === this.defaultValue)
			this.changedItem(item)
		}
		this.selectTypeahead
			.pipe(
				debounceTime(DEBOUNCE_TIME_IN_MILLISECONDS),
				switchMap(term => this.reloadItems(term)),
			)
			.subscribe(
				(items: any) => {
					this.cd.markForCheck() /**/
				},
				err => {
					this.cd.markForCheck() /**/
				},
			)
	}

	changedItem(item: SimpleSelectItem) {
		if (item) {
			this.valueFormControl.setValue(item)
			this.onSelect.emit(item)
		}
	}

	clear() {
		this.valueFormControl.reset()
		this.onClear.emit()
	}

	scrollToEnd(searchTerm: string) {
		this.onScrollToEnd.emit({ searchTerm })
	}

	scroll({ end }, searchTerm?: string) {
		this.onScroll.emit({ end, searchTerm })
	}
}
