import {BrowserModule, Title} from '@angular/platform-browser'
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core'
// https://angular.io/guide/reactive-forms
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ClipboardModule} from 'ngx-clipboard'

// *******************************************************************************
// NgBootstrap

import {
	NgbModule,
	NgbDateAdapter,
	NgbDateParserFormatter,
	NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap'

// *******************************************************************************
// Libs

import Swal from 'sweetalert2'
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2'
import {ToastrModule} from 'ngx-toastr'
import {ConfirmationPopoverModule} from 'angular-confirmation-popover'
import {ContextMenuModule} from 'ngx-contextmenu'
import {AgmCoreModule} from '@agm/core'
import {BlockUIModule} from 'ng-block-ui'
import {CalendarModule, DateAdapter} from 'angular-calendar'
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns'
import {DragulaModule} from 'ng2-dragula'

// import ngx-translate and the http loader
// https://github.com/ngx-translate/core
//
// Esse resolveu o translate: -> https://medium.com/@lopesgon/translate-angular-4-with-ngx-translate-and-multiple-modules-7d9f0252f139
// https://stackblitz.com/github/ngx-translate/example?file=src%2Fapp%2Fapp.component.ts
import {HttpClient, HttpClientModule} from '@angular/common/http'
import {TranslateModule, TranslateLoader} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader'

import {NgxLowerCaseDirectiveModule} from 'ngx-lower-case-directive'
import {NgxUpperCaseDirectiveModule} from 'ngx-upper-case-directive'
// https://github.com/urish/ngx-moment#readme
import {MomentModule} from 'ngx-moment'

// *******************************************************************************
// App

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {NotFoundComponent} from './not-found/not-found.component'
import {AppService} from './app.service'
import {LayoutModule} from './layout/layout.module'
import {ThemeSettingsModule} from '../vendor/libs/theme-settings/theme-settings.module'
import {UrlSerializer} from '@angular/router'

// *******************************************************************************
// Ngx-SweetAlert2

export async function provideSwal() {
	return Swal.mixin({
		buttonsStyling: false,
		customClass: {
			confirmButton: 'btn btn-lg btn-primary',
			cancelButton: 'btn btn-lg btn-default',
		},
	})
}

// *******************************************************************************
//

// *******************************************************************************
// Dant - TranslateHttpLoader
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient)
	// return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

import {LOCALE_ID} from '@angular/core'
import {registerLocaleData} from '@angular/common'
import localePt from '@angular/common/locales/pt'
import {CustomAdapter, CustomDateParserFormatter} from './datepicker-adapter'

registerLocaleData(localePt)

// https://github.com/JsDaddy/ngx-mask
import {NgxMaskModule, IConfig} from 'ngx-mask'
import {DropzoneModule} from 'ngx-dropzone-wrapper'

const maskConfig: Partial<IConfig> = {
	validation: false,
}

// https://github.com/basst314/ngx-webcam#readme
import {WebcamModule} from 'ngx-webcam'
// https://github.com/JerryFZhang/ng-push-ivy
import {PushNotificationsModule} from 'ng-push-ivy'
import {TrackCapsDirective} from './directives/TrackCapsDirective'

import {TimeagoModule, TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter} from 'ngx-timeago'

import {CurrencyMaskModule} from 'ngx-currency-mask'
import {InterceptorModule} from './service/interceptor/interceptor.module'
import {LandingPageComponent} from './landing-page-cct/landing-page.component'
import {LandingPageUsuarioComponent} from './landing-page-usuario/landing-page-usuario.component'
import {CardImagemLandingPageComponent} from './others-component/cards/card-imagem-landing-page/card-imagem-landing-page.component'
import {NavbarLandingPageComponent} from './others-component/navbar/navbar-landing-page/navbar-landing-page.component'
import {CardAtendimentoLandingPageComponent} from './others-component/cards/card-atendimento-landing-page/card-atendimento-landing-page.component'
import {CardBeneficiosCctComponent} from './others-component/cards/card-beneficios-cct/card-beneficios-cct.component'
import {LandingPageInfoCardComponent} from './others-component/cards/landing-page-info-card/landing-page-info-card.component'
import {SecRecifeComponent} from './agiben/paf/secrecife/secrecife.component'
import {PafSintibrefComponent} from './agiben/paf/paf-sintibref/paf-sintibref.component'

import {IndexAgibenComponent} from './agiben/index-agiben/index-agiben.component'
import {BeneficiosAgibenComponent} from './agiben/beneficios-agiben/beneficios-agiben.component'
import {PafGenericoComponent} from './agiben/paf/paf-generico/paf-generico.component'
import {LowerCaseUrlSerializer} from './url-adapter'
import {CustomModalService} from './service/modal/custom-modal.service'
import {QuillModule} from 'ngx-quill'
import {ManutencaoComponent} from './manutencao/manutencao.component'
import {RedirecionamentoGrupoCctComponent} from './agiben/redirecionamento-grupo-cct/redirecionamento-grupo-cct.component'
import {ModalGrupoCctComponent} from './others-component/popups/modal-grupo-cct/modal-grupo-cct.component'
import {CardClubeBeneficiosComponent} from './others-component/cards/card-clube-beneficios/card-clube-beneficios.component'
import { NotificacaoService } from './service/notificacao.service'

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		LandingPageComponent,
		LandingPageUsuarioComponent,
		CardImagemLandingPageComponent,
		NavbarLandingPageComponent,
		CardAtendimentoLandingPageComponent,
		CardBeneficiosCctComponent,
		SecRecifeComponent,
		PafSintibrefComponent,
		LandingPageInfoCardComponent,
		CardClubeBeneficiosComponent,
		IndexAgibenComponent,
		BeneficiosAgibenComponent,
		PafGenericoComponent,
		ManutencaoComponent,
		RedirecionamentoGrupoCctComponent,
	],

	imports: [
		BrowserModule,
		FormsModule,
		// https://angular.io/guide/reactive-forms
		// https://www.itsolutionstuff.com/post/angular-9-reactive-form-validation-exampleexample.html
		ReactiveFormsModule,
		BrowserAnimationsModule,
		NgbModule,

		// App
		AppRoutingModule,
		LayoutModule,
		ThemeSettingsModule,

		// Libs
		SweetAlert2Module.forRoot({provideSwal}),
		ToastrModule.forRoot({
			toastClass: 'toast',
		}),
		ConfirmationPopoverModule.forRoot({
			cancelButtonType: 'default btn-sm',
			confirmButtonType: 'primary btn-sm',
		}),
		ContextMenuModule.forRoot(),
		AgmCoreModule.forRoot({
			/* NOTE: When using Google Maps on your own site you MUST get your own API key:
               https://developers.google.com/maps/documentation/javascript/get-api-key
               After you got the key paste it in the URL below. */
			apiKey: 'AIzaSyCHtdj4L66c05v1UZm-nte1FzUEAN6GKBI',
		}),
		BlockUIModule.forRoot(),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		DragulaModule.forRoot(),

		// By dant
		// ngx-translate and the loader module
		// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular8-app-with-ngx-translate
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		NgxMaskModule.forRoot(),
		NgxUpperCaseDirectiveModule,
		NgxLowerCaseDirectiveModule,
		DropzoneModule,
		WebcamModule,
		MomentModule,
		// https://github.com/JerryFZhang/ng-push-ivy
		PushNotificationsModule,
		// https://type.vn/cap-nhat-thoi-gian-thuc-bang-ngx-timeago-7705.ty
		TimeagoModule.forRoot({
			intl: {provide: TimeagoIntl},
			formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
		}),
		CurrencyMaskModule,
		InterceptorModule,
		QuillModule.forRoot(),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add this line

	exports: [],

	providers: [
		Title,
		AppService,
		{provide: LOCALE_ID, useValue: 'pt-BR'},
		{provide: NgbDateAdapter, useClass: CustomAdapter},
		{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
		// https://github.com/ng-bootstrap/ng-bootstrap/issues/1755#issuecomment-344088034
		{provide: UrlSerializer, useClass: LowerCaseUrlSerializer},
		NgbActiveModal,
		TimeagoIntl,
		CustomModalService,
		NotificacaoService
	],

	bootstrap: [AppComponent],
})
export class AppModule {}
