<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">



  <!-- Brand demo (see demo.css) -->
  <a class="navbar-brand app-brand demo d-lg-none py-0 mr-4">

    <span class="app-brand-logo demo bg-primary">

      <!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="256px" height="256px" viewBox="0 0 256 256" enable-background="new 0 0 256 256"
        xml:space="preserve">
        <path fill="#FFFFFF" d="M253.725,127.805c0,69.443-56.283,125.735-125.724,125.735c-69.438,0-125.726-56.292-125.726-125.735
     C2.275,58.38,58.563,2.09,128.001,2.09C197.439,2.09,253.725,58.38,253.725,127.805z" />
        <g>
          <path fill="none" stroke="#000000" stroke-width="14" stroke-miterlimit="10" d="M167.658,123H87.984v-21.931
       c0-14.271,3.27-25.052,9.807-32.343c6.538-7.291,16.196-10.936,28.975-10.936c6.148,0,11.742,1.018,16.779,3.054
       c5.037,2.036,9.344,4.973,12.918,8.813c3.576,3.839,6.334,8.493,8.279,13.961c1.943,5.468,2.916,11.634,2.916,18.498V123z
        M94.763,115.263h66.172v-12.797c0-5.739-0.74-10.868-2.224-15.386c-1.479-4.517-3.655-8.347-6.526-11.488
       c-2.869-3.142-6.418-5.536-10.642-7.184c-4.224-1.648-9.073-2.472-14.558-2.472c-21.483,0-32.226,11.887-32.226,35.658
       L94.763,115.263L94.763,115.263z" />
        </g>
        <g>
          <rect x="80.966" y="144.367" width="93.71" height="21.581" />
          <rect x="117.209" y="165.948" width="21.582" height="38.219" />
        </g>
      </svg> -->

      <svg data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
        <path
          d="M493.74 524.26h-15.11a13.6 13.6 0 0 1-10.84-5.38l-16.35-21.51q-33.35 19.36-40.32 22a112.67 112.67 0 0 1-45.71 9.68q-39.8 0-64-24.74-25.81-25.82-25.81-69.9 0-38.18 29.57-60.49t78-22.31a261 261 0 0 1 59.14 7v-6.46q0-61.29-47.31-61.29-16.07 0-68.27 13.22a13.62 13.62 0 0 1-15.89-7.89l-8.49-20.06a13.61 13.61 0 0 1 9.51-18.58Q375.33 243 408.43 243q53.24 0 73.66 30.65 16.14 24.19 16.13 78v115.07l8.83 41.06a13.62 13.62 0 0 1-13.31 16.48Zm-51.44-72.94v-39.08A13.57 13.57 0 0 0 431.77 399a163.68 163.68 0 0 0-36.25-4.31q-30.64 0-47.58 11.29T331 436.07q0 18.84 16.13 30.38T387.46 478q31.47 0 50.25-16.49a13.56 13.56 0 0 0 4.59-10.19Zm300.87-60.41h-15.12a13.61 13.61 0 0 0-10.83 5.37l-16.35 21.51q-33.35-19.35-40.33-22a112.42 112.42 0 0 0-45.7-9.68q-39.8 0-64 24.73-25.8 25.82-25.81 69.9 0 38.18 29.58 60.49t78 22.32a261.63 261.63 0 0 0 59.15-7V563q0 61.31-47.32 61.3-16.06 0-68.27-13.22a13.61 13.61 0 0 0-15.88 7.88L551.76 639a13.62 13.62 0 0 0 9.51 18.58q63.48 14.55 96.58 14.55 53.24 0 73.67-30.65 16.13-24.19 16.13-78V448.44l8.83-41.06a13.61 13.61 0 0 0-13.31-16.47Zm-51.44 72.93v39.09a13.58 13.58 0 0 1-10.53 13.26 164.31 164.31 0 0 1-36.25 4.3q-30.65 0-47.59-11.29t-16.93-30.11q0-18.82 16.13-30.38t40.32-11.56q31.47 0 50.26 16.49a13.56 13.56 0 0 1 4.59 10.2ZM293.81 702.72a15.86 15.86 0 0 1 20.89.6q73.35 67.92 199.81 67.91 67.61 0 114.55-15a241.28 241.28 0 0 0 89.54-51.29 15.83 15.83 0 0 1 20-.92l11.77 8.73a15.85 15.85 0 0 1 2.09 23.61q-47.52 50.28-119.64 77.46Q570.84 837 514.51 837q-58.83 0-122.06-25.67-66-27.15-112.32-75a15.87 15.87 0 0 1 1.22-23.3Z" />
        <rect x="751" y="656.01" width="47.94" height="49.37" rx="12.02" transform="rotate(-49.09 774.955 680.695)"
          style="fill:#f2ab27" />
      </svg>


    </span>
    <span class="app-brand-text demo font-weight-normal ml-2">Agiben</span>
  </a>

  <!-- Sidenav toggle (see demo.css) -->
  <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto" *ngIf="sidenavToggle">
    <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
      <i class="ion ion-md-menu text-large align-middle"></i>
    </a>
  </div>

  <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
    <i class="far fa-user navbar-icon"></i>
    <span *ngIf="qtdMensagem > 0" class="badge badge-primary badge-dot indicator"></span>
  </button>

  <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
    <!-- Divider -->
    <hr class="d-lg-none w-100 my-2">

    <div class="navbar-nav align-items-lg-center">
      <!-- Search -->
      <label class="nav-item navbar-text navbar-search-box p-0 active">
        <i class="ion ion-ios-search navbar-icon align-middle" *ngIf="false"></i>
        <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="searching">
          <span class="sr-only">Loading...</span>
        </div>

        <span class="navbar-search-input pl-2">

          <!-- Form -->
          <form [formGroup]="form" #ngForm="ngForm" class="form-inline" novalidate>

            <!-- With template -->
            <ng-template #rt let-r="result" let-t="term">
              <div *ngIf="r.usu_status == 'I'" class="badge badge-danger">{{'status.inativo' | translate }}
              </div>
              <ngb-highlight [result]="r.usu_nome" [term]="t"></ngb-highlight>
              <div class="clearfix">
                <span class="float-left small">{{ 'perfil_de_acesso.' + r.fk_idperfisdeacesso |
                  translate}}</span>
              </div>

            </ng-template>

            <input id="typeahead-template" type="text" class="form-control navbar-text mx-2" *ngIf="false"
              style="width:200px" formControlName="search_term" id="search_term" [ngbTypeahead]="search"
              [resultTemplate]="rt" [inputFormatter]="formatter" [placement]="isRTL ? 'bottom-right' : 'bottom-left'"
              (click)="clear()" (selectItem)="selectedItem($event)" placeholder="{{ 'Busca rápida' | translate }}">
          </form>

        </span>
      </label>
    </div>



    <div class="navbar-nav align-items-lg-center ml-auto">
      <div *ngIf="!state.is404[0]" ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
        class="demo-navbar-notifications nav-item mr-lg-3">

        <notificacao-component [state]="state" [isRTL]="isRTL" [notificacoes]="notificacoes"
          (marcarNotificacoes)="marcarNotificacoesComoVisualizadas()"></notificacao-component>

        <div ngbDropdownMenu [class.dropdown-menu-right]="isRTL" style="max-height: 400px; overflow: auto;">
          <div class="bg-primary text-center text-white font-weight-bold p-3"
            *ngIf="state.listFilterDevices && state.paging.total > 10">
            <!-- Form -->
            <form [formGroup]="formDevice" #ngForm="ngForm" novalidate>
              <input type="text" class="form-control navbar-text" formControlName="searchTerm"
                placeholder="{{ 'busca_rapida.digite_sua_pesquisa' | translate }}"
                style="margin-left: auto; margin-right: auto;" (input)="filterDevices($event.target.value)" upperCase>
            </form>
          </div>
          <div class="list-group list-group-flush">
            <a href="javascript:void(0)" *ngFor="let obj of state.listFilterDevices"
              (click)="openLiberacaoManualPopup(obj)"
              class="list-group-item list-group-item-action media d-flex align-items-center">

              <div class="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body" *ngIf="!obj.dis_online">
              </div>
              <div class="media-body line-height-condenced ml-3">
                <div class="text-body">{{ obj.dis_descricao }}</div>
                <div class="text-light small mt-1">
                  <a *ngIf="obj.dis_online == true" class="badge badge-pill badge-success"
                    href="javascript:void(0)">{{'dispositivo.list.online' | translate }}</a>
                  <a *ngIf="obj.dis_online == false" class="badge badge-pill badge-danger"
                    href="javascript:void(0)">{{'dispositivo.list.offline' | translate }}</a>
                </div>
                <div class="text-light small mt-1" *ngFor="let leitor of obj.leitores_json">
                  <span class="badge badge-danger badge-dot indicator" *ngIf="!obj.dis_online"></span>
                  <a *ngIf="obj.dis_online && leitor.dis_sensorstatus" class="badge badge-outline-danger mr-2"
                    href="javascript:void(0)">{{'dispositivo.list.porta_aberta' | translate }}</a>
                  <a *ngIf="obj.dis_online && !leitor.dis_sensorstatus" class="badge badge-outline-success"
                    href="javascript:void(0)">{{'dispositivo.list.porta_fechada' | translate }}</a>
                  &nbsp;
                  {{ leitor.dis_descricao }}
                </div>

              </div>
            </a>
          </div>
        </div>
      </div>





      <div *ngIf="qtdMensagem > 0" ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
        class="demo-navbar-messages nav-item mr-lg-3">
        <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
          <i class="ion ion-ios-mail navbar-icon align-middle"></i>
          <span class="badge badge-primary badge-dot indicator"></span>
          <span class="d-lg-none align-middle">&nbsp; {{(isMaiorQueUm() ? 'Notificações' : 'Notificação')}}</span>
        </a>
        <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <div class="bg-primary text-center text-white font-weight-bold p-3">
            {{qtdMensagem}} {{(isMaiorQueUm() ? 'Notificações' : 'Notificação')}}


            <!-- <img src="{{ isMensal(fp) ? './assets/img/boleto_agiben.png': './assets/img/cartao_agibeen.png' }}"
            alt="Circle Image" class="img-circle "> -->

          </div>


          <div class="list-group list-group-flush" *ngFor="let o of state.list_compromisso">
            <a href="javascript:void(0)" [routerLink]="'/cadastros/compromisso/' + o.pk_idcompromisso + '/edit'"
              class="list-group-item list-group-item-action media d-flex align-items-center">

              <div class="media-body ml-3">
                <div class="text-body line-height-condenced">
                  <span class="badge badge-outline-primary mb-2">Compromisso</span> <br />

                  <div><b>CPM-{{ o.pk_idcompromisso |
                      numeroCompromisso }}:{{o.empresa.emp_nomefantasia}}</b></div>


                  <small style="margin-top: -5px;">
                    ({{ o.com_statusreuniao | tipoStatusCompromisso }}) - {{o.com_inicio | dataIsoToPtbr}}
                  </small>


                </div>
              </div>
            </a>
          </div>


          <div class="list-group list-group-flush" *ngFor="let o of state.list_mensagem_grupo">

            <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">

              <div class="media-body ml-3">
                <span class="badge badge-outline-default mb-1">Mensagem</span>
                <div class="text-body line-height-condenced">
                  <div [innerHTML]="o.men_texto"></div>

                </div>
                <div class="text-light small">
                  <!-- Mae Gibson &nbsp;·&nbsp; 58m ago -->

                  <small style="margin-top: -5px;">
                    [ <a *ngFor="let grupo of o.grupos_json">{{grupo.gru_nome}} </a>
                    <a *ngIf="!o.grupos_json">Todos</a> ] -
                    Expira em: {{o.men_dataexpiracao | date: 'shortDate'}}
                  </small>
                </div>
              </div>
            </a>


          </div>

          <!-- <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all messages</a> -->
        </div>
      </div>



      <!-- Divider -->
      <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

      <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
        class="demo-navbar-user nav-item d-flex justify-content-end">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <i *ngIf="!img" class="ion ion-md-person d-block"></i>
            <img *ngIf="img" [src]="img" alt class="d-block ui-wh-30 rounded-circle" (error)="img = null">
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ getNomeUsuario | titlecase }}</span>
          </span>
        </a>
        <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <a href="javascript:void(0)" (click)="openNewPasswordPopup()" class="dropdown-item"><i
              class="ion ion-ios-lock text-lightest"></i> &nbsp;
            {{ 'conta.edit.alterar_senha' | translate }}</a>
          <a *ngIf="false" href="javascript:void(0)" class="dropdown-item"><i
              class="ion ion-ios-mail text-lightest"></i> &nbsp;
            Messages</a>
          <a href="javascript:void(0)" (click)="openAccount()" class="dropdown-item"><i
              class="ion ion-md-settings text-lightest"></i> &nbsp;
            Editar perfil</a>

          <a href="https://agiben.com.br/" target="_blank" class="dropdown-item"><i
              class="ion ion-ios-help-buoy text-lightest"></i> &nbsp;
            Sobre</a>

          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" (click)="logout()" class="dropdown-item"><i
              class="ion ion-ios-log-out text-danger"></i> &nbsp; {{ 'menu.logout' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</nav>