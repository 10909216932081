import {Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {environment} from 'src/environments/environment'

@Component({
	selector: 'app-paf-sintibref',
	templateUrl: './paf-sintibref.component.html',
	styleUrls: ['./paf-sintibref.component.scss']
})
export class PafSintibrefComponent implements OnInit {
	@ViewChild('modalLink', {static: true}) modalLink: ElementRef
	sindicatoSelecionado: any
	arrayDeConteudos = [
		{
			id: 1,
			Nome: 'SINTIBREF',
			Valor_Titular: 50.09,
			Valor_Dependente: 14.76,
			Procedimentos: [
				'Plano Odontológico',
				'Telemedicina',
				'Assistência Natalidade',
				'Teleterapia',
				'Seguro de Vida'
			],
			Link: 'https://agiben.com.br/cadastro-empresa-sindicato/antigo?v=7024&p=7218&e=124&l=%5B%7B"produto":3,%20"valor":19.90%7D,%20%7B"produto":22,%20"valor":50.09%7D,%20%7B"produto":25,%20"valor":67.78%7D%5D',
			Link_CCT:
				'https://api.plataformavendas.agiben.com.br/api/site/cct?arquivo=cct-14-data-20-12-2023,%2021:24:24-CCT%20ACT%20vigente.pdf',
			Assistencia: true
		}
	]

	constructor(
		private modalService: NgbModal,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		if (this.modalLink) {
			this.modalLink.nativeElement.click()
		}
	}

	formatarCasasDecimais(valor: number): string {
		return (valor % 1).toFixed(2).split('.')[1]
	}

	open(content, options = {}) {
		this.modalService.open(content, options).result.then(
			result => {
				console.log(`Closed with: ${result}`)
			},
			reason => {
				console.log(`Dismissed ${this.getDismissReason(reason)}`)
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop'
		} else {
			return `with: ${reason}`
		}
	}

	selecionarSindicato(id: number, modalClose: any) {
		this.sindicatoSelecionado = this.arrayDeConteudos.find(item => item.id === id)

		if (this.sindicatoSelecionado) {
		}

		modalClose()
	}

	scrollToSection(nomeDiv: any, page: any) {
		if (page === 'Home') {
			this.router.navigate(['../'], {
				relativeTo: this.route,
				fragment: nomeDiv,
				skipLocationChange: true
			})
		} else if (page === 'Beneficios') {
			this.router.navigate(['/home/beneficios'], {
				relativeTo: this.route,
				fragment: nomeDiv,
				skipLocationChange: false // Defina como true se você desejar ocultar a mudança na barra de endereços
			})
		}
	}

	redirectTo(url: string): void {
		window.location.href = url
	}
}
