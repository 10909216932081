import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {ApiService} from './api.service'
import {
	BuscarNotificacoesPorUsuarioBody,
	VisualizarNotificacoesBody
} from '../others-component/notificacao-component/notificacao-dto'

@Injectable({
	providedIn: 'root'
})
export class NotificacaoService {
	urlServerAPI: string
	urlServerAPINest: string

	prefixUrl = 'notificacao'

	constructor(
		private apiService: ApiService,
		private http: HttpClient
	) {
		this.urlServerAPI = environment.API_URL
		this.urlServerAPINest = environment.API_URL_NEST
	}

	async buscarNotificacoesPorUsuario(
		buscarNotificacoesPorUsuarioBody: BuscarNotificacoesPorUsuarioBody
	) {
		try {
			const urlAPI = `${this.urlServerAPINest}${this.prefixUrl}/listar`
			return await this.http.post(urlAPI, buscarNotificacoesPorUsuarioBody).toPromise()
		} catch (error) {
			this.apiService.handleError(error, 'buscarNotificacoesPorUsuario')
		}
	}

	async visualizarNotificacoes(visualizarNotificacoesBody: VisualizarNotificacoesBody) {
		try {
			const urlAPI = `${this.urlServerAPINest}${this.prefixUrl}/visualizar`
			return await this.http.patch(urlAPI, visualizarNotificacoesBody).toPromise()
		} catch (error) {
			this.apiService.handleError(error, 'visualizarNotificacoes')
		}
	}
}
