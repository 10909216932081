// src/app/services/socket.service.ts
import {Injectable} from '@angular/core'
import io from 'socket.io-client'
import {Socket} from 'socket.io-client'
import {Observable} from 'rxjs'
import { environment } from 'src/environments/environment'
import { WebSocketEvents } from './interface/websocket.enum'

@Injectable({
	providedIn: 'root'
})
export class SocketService {
	private socket: Socket    
	private socketId: string

	constructor() {

	}

	initConnection(userId: number) {
		if(!this.socket) {
			this.socket = io(environment.WEBSOCKET_URL, {withCredentials: true})
	
			this.on(WebSocketEvents.CONNECT).subscribe((_) => {
				this.socket.emit(WebSocketEvents.USER_LOGIN, {
					content: `Id do usuário: ${userId}`,
					platform: 'Plataforma de vendas',
					clientId: userId?.toString()
				})			
			})		
	
			this.on(WebSocketEvents.USER_SOCKET_ID).subscribe((socketId) =>{				
				this.setSocketId(socketId)
			})
	
			this.on(WebSocketEvents.DISCONNECT).subscribe((data) =>{
				sessionStorage.removeItem(WebSocketEvents.USER_SOCKET_ID)
			})
		}
	}

	emit(event: string, data: any) {
		this.socket.emit(event, data)
	}

	on(event: string): Observable<any> {
		return new Observable(observer => {
			this.socket.on(event, data => {
				observer.next(data)
			})

			// Handle cleanup
			return () => {
				this.socket.off(event)
			}
		})
	}

	setSocketId(socketId: string) {
		this.socketId = socketId
		sessionStorage.setItem(WebSocketEvents.USER_SOCKET_ID, this.socketId)
	  }

	getSocketId(userId?: string) {
		this.socketId = sessionStorage.getItem(WebSocketEvents.USER_SOCKET_ID)
		if(!this.socketId) {        
		  this.emit(WebSocketEvents.USER_LOGIN, {
			content: `Id do usuário: ${userId}`,
			platform: 'Plataforma de Vendas',
			clientId: userId?.toString()
		})
		}
	  }
  
}
