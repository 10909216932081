import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core'
import {NotificacaoService} from 'src/app/service/notificacao.service'
import {TipoReferenciaNotificacao} from './TipoReferenciaNotificacao'
import {Router} from '@angular/router'
import {NotificacaoDto} from './notificacao-dto'
import {ApiService} from 'src/app/service/api.service'
import {retornarMsgErroPorStatus} from 'src/app/utils/util'

@Component({
	selector: 'notificacao-component',
	templateUrl: './notificacao-component.component.html',
	styleUrls: ['./notificacao-component.componente.scss']
})
export class NotificacaoComponentComponent implements OnInit {
	@Input() state: {is404: [boolean]} = {is404: [false]}
	@Input() isRTL: boolean
	@Input() notificacoes: NotificacaoDto[] = []
	@Output() marcarNotificacoesComoVisualizadas = new EventEmitter<void>()

	constructor(
		private notificacaoService: NotificacaoService,
		private router: Router,
		private apiService: ApiService
	) {}

	ngOnInit(): void {}

	get notificacoesNaoLidas(): boolean {
		return this.notificacoes.some(notificacao => !notificacao.visualizado)
	}

	formatarHora(data: string): string {
		const dateObj = new Date(data)
		return dateObj.toLocaleTimeString('pt-BR', {
			hour: '2-digit',
			minute: '2-digit'
		})
	}

	marcarComoVisualizado(notificacao: NotificacaoDto): void {
		notificacao.visualizado = true

		this.notificacaoService
			.visualizarNotificacoes({notificacoesIds: [notificacao.id]})
			.then(() => {
				this.atualizarListaNotificacoes()

				this.redirecionar(notificacao)
			})
			.catch(error => {
				notificacao.visualizado = false
				this.apiService.showToast(null, retornarMsgErroPorStatus(error), 'error')
			})
	}

	marcarTodasComoVisualizadas(): void {
		const notificacoesIds = this.notificacoes.map(notificacao => notificacao.id)

		this.notificacaoService
			.visualizarNotificacoes({notificacoesIds})
			.then(() => {
				this.notificacoes.forEach(notificacao => {
					notificacao.visualizado = true
				})

				this.atualizarListaNotificacoes()

				this.marcarNotificacoesComoVisualizadas.emit()
			})
			.catch(error => {
				this.apiService.showToast(null, retornarMsgErroPorStatus(error), 'error')
			})
	}

	atualizarListaNotificacoes() {
		this.notificacoes = [...this.notificacoes]
	}

	redirecionar(notificacao: NotificacaoDto): void {
		const tipoReferencia = notificacao.tipoReferencia
		const idReferencia = notificacao.referenciaId

		switch (tipoReferencia) {
			case TipoReferenciaNotificacao.DESPESA:
				this.router.navigate([`/despesas/${idReferencia}`])
				break
			case TipoReferenciaNotificacao.LEAD_PROPOSTA:
				this.router.navigate([`/leads/${idReferencia}/edit`])
				break
			case TipoReferenciaNotificacao.LEAD_CONTRATO:
				this.router.navigate([`/leads/${idReferencia}/edit`])
				break
			case TipoReferenciaNotificacao.CCT:
				this.router.navigate([`/cct/${idReferencia}/parametrizacao-edit`])
				break
			case TipoReferenciaNotificacao.ANALISE_CCT:
				this.router.navigate([`/cct/${idReferencia}/parametrizacao-edit`])
				break
			default:
				this.apiService.showToast(
					null,
					'Tipo de referência não encontrado. Por favor, verifique.',
					'error'
				)
				break
		}
	}
}
